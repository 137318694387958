import React from 'react';
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import {Button, Grid, Typography} from "@mui/material";
import axios from "axios";

const ConfirmInfo = ({linkData, setIsComp}) => {

    const color = useRecoilValue(colorState);

    const completeHandler = () => {
            setIsComp(true);

        // axios.get(`/api/auth/updateStatus/${linkUrl}`).then(res => {
        // }).catch();
    }


    return (
        <>
            <Grid
                item
                container
                xs={11}
                sx={{
                    position: 'absolute',  // 절대 위치 사용
                    bottom: '0vh',  // 하단에서 8vh 위에 위치
                    backgroundColor: '#D4D4D4',
                    borderTopLeftRadius: '16px',  // 상단 좌측 모서리 라운드 처리
                    borderTopRightRadius: '16px',  // 상단 우측 모서리 라운드 처리
                    overflow: 'hidden',  // 자식 요소가 부모 요소를 넘지 않도록 설정
                }}
                display={'flex'}
                justifyContent={'center'}  // 수평 중앙 정렬
                alignItems={'start'}  // 수직 중앙 정렬
            >
                <Grid item xs={10}
                      display={'flex'}
                      justifyContent={'center'}
                      flexDirection={'column'}
                >
                    <Typography variant="body1"
                                sx={{
                                    fontSize: '0.8rem',
                                    height: '8rem',
                                    color: '#000000',
                                    marginLeft: 0,
                                    padding: 0.5,
                                    paddingTop: 1
                                }}>
                        {'파손이나 반품의 의사가 있을 경우 판매자와 상의하시기 바랍니다. ' +
                            '상품 확인 및 수령을 완료하신 후에는 상품 미수령의 이유로 인한 '}
                        <span style={{fontWeight: 'bold', color: 'red'}}>
                         결제 취소가 불가능
                        </span>
                        {'합니다. 이 후 제품의 A/S, 파손 등의 불만족으로 인한 취소는 ' +
                            '판매자와 고객의 합의로 진행되며 저희 GHPAY는 이를 책임지지 않습니다'}
                    </Typography>
                    <Typography variant={'body1'} sx={{
                        fontSize: '1.1rem',
                        color: '#000000', fontWeight: 'bold', height: '30px', marginLeft: 2, padding: 0.5
                    }}>
                        위 상품을 확인 및 수령하셨나요?
                    </Typography>
                </Grid>
                <Grid item xs={6}
                      display={'flex'}
                      justifyContent={'center'}  // 수평 중앙 정렬
                >
                    <Button
                        fullWidth
                        sx={{
                            marginX: 0.5,
                            marginY: 1,
                            paddingY: 2,
                            height: '60px',
                            backgroundColor: color,
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            borderRadius: 3,
                            '&:hover': {backgroundColor: '#999999'}
                        }}
                        onClick={completeHandler}
                    >
                        네. 확인 및 수령했습니다.
                    </Button>

                </Grid>
                <Grid item xs={6}
                      display={'flex'}
                      justifyContent={'center'}  // 수평 중앙 정렬
                >
                    <Button
                        fullWidth
                        sx={{
                            marginX: 0.5,
                            marginY: 1,
                            paddingY: 2,
                            height: '60px',
                            backgroundColor: '#F4F5F7',
                            color: '#B4B4B4',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            borderRadius: 3,
                            '&:hover': {backgroundColor: '#999999'}
                        }}
                    >
                        아니오
                    </Button>

                </Grid>
            </Grid>
        </>
    );
};

export default ConfirmInfo;
