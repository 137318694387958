import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {Box, Grid} from "@mui/material";
import SHeader from "../layout/SHeader";
import PayPage from "./PayPage";
import ConfirmComp from "./ConfirmComp";

const ConfirmUrl = () => {

    const [itemList, setItemList] = useState([])
    const [shopData, setShopData] = useState({})
    const [linkData, setLinkData] = useState({})

    const {link} = useParams();
    const [isComp, setIsComp] = useState(false)

    useEffect(() => {
        getData();
    }, [link]);

    const getData = () => {
        axios.get(`/api/auth/confirmLink/${link}`).then(res => {
            setItemList(res.data.linkDetailData);
            setShopData(res.data.member);
            setLinkData(res.data.linkData);
        })
    }

    return (
        <>
            <Box sx={{backgroundColor: '#F4F5F7', width: '100vw', height: '100vh'}}>
                <SHeader btnState={'hide'}></SHeader>
                <Grid container display={'flex'} justifyContent={'center'} sx={{ paddingTop: 6 }}>
                    {isComp ?
                        <ConfirmComp linkData={linkData}/>
                        :
                        <PayPage linkUrl={link} setIsComp={setIsComp} itemList={itemList} shopData={shopData} linkData={linkData} link={link}/>
                    }
                </Grid>
            </Box>
        </>
    );
};

export default ConfirmUrl;
