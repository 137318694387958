import React, {useEffect, useState} from 'react';
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useRecoilValue} from "recoil";
import {memberState} from "../../store/memberStore";
import {useNavigate} from "react-router-dom";
import axios from "../../axiosInstance";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const MbileTableCell = (props) => {

    return (
        <TableCell
            {...props}
            sx={{...props.sx, fontSize: '12px', padding: 0.5, paddingY: 1}}
        >
            {props.children}
        </TableCell>
    );
};
const SList = () => {

    const [data, setData] = useState([]);
    const memberInfo = useRecoilValue(memberState);
    const navigate = useNavigate();
    const [rowVisibility, setRowVisibility] = useState({});


    useEffect(() => {
        getData()
    }, []);
    const getData = () => {

        axios.get(`/api/store/completed/${memberInfo.memCode}`).then(res => {
            setData(res.data);
        }).catch();
    }
    const handleClick = (data) => {
        navigate('/linkDetail', { state: { data: data } });
    };

    const toggleRowVisibility = (id) => {
        setRowVisibility(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    return (
        <>
            <Grid container display={'flex'} justifyContent={'center'}>
                <Grid item xs={11}>
                    <TableContainer component={Paper} sx={{padding: 0, borderRadius: 2}}>
                        <Table sx={{fontSize: '8px'}}>
                            <TableHead sx={{ backgroundColor: '#D9D9D9'}}>
                                <TableRow>
                                    <MbileTableCell align="center"
                                                    sx={{padding: 0, fontSize: '10px', width: '20%'}}
                                                    component="th">
                                        구매자
                                    </MbileTableCell>
                                    <MbileTableCell align="center" component="th">
                                        연락처
                                    </MbileTableCell>
                                    <MbileTableCell align="center" component="th" colspan={1}>
                                        결제금액
                                    </MbileTableCell>
                                    <MbileTableCell align="center" component="th" colspan={1}>
                                        상세
                                    </MbileTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((d, idx) => (
                                    <>
                                        <TableRow key={idx} align="center"
                                                  sx={{
                                                      '&:hover': {
                                                          backgroundColor: 'rgba(0, 0, 0, 0.04)', // 호버 시 원하는 색상으로 변경
                                                      },
                                                  }}>
                                            <MbileTableCell align="center">
                                                {d.NAME}
                                            </MbileTableCell>
                                            <MbileTableCell align="center">
                                                {d.TELL}
                                            </MbileTableCell>
                                            <MbileTableCell align="center">
                                                {d.PRICE.toLocaleString()}
                                            </MbileTableCell>
                                            <MbileTableCell align="center">
                                                <ExpandMoreIcon onClick={() => toggleRowVisibility(idx)}></ExpandMoreIcon>
                                            </MbileTableCell>
                                        </TableRow>
                                        {rowVisibility[idx] && (
                                            <>
                                                <TableRow sx={{ paddingX: 0 }}>
                                                    <MbileTableCell align="center" sx={{ width: '20%' }}>
                                                        승인번호
                                                    </MbileTableCell>
                                                    <MbileTableCell align="center" sx={{ width: '40%' }} colspan={1}>
                                                        {'123781231'}
                                                    </MbileTableCell>
                                                    <MbileTableCell align="center">
                                                        결제일시 :
                                                    </MbileTableCell>
                                                    <MbileTableCell align="center">
                                                        {'202404031212'}
                                                    </MbileTableCell>
                                                </TableRow>
                                                <TableRow sx={{ borderBottom: '2px' }}>
                                                    <MbileTableCell align="center">
                                                        카드번호
                                                    </MbileTableCell>
                                                    <MbileTableCell align="center" colspan={2} sx={{ fontSize: '9px' }}>
                                                        {'123412******12'}
                                                    </MbileTableCell>
                                                    <MbileTableCell align="center">
                                                        일시불
                                                    </MbileTableCell>
                                                </TableRow>
                                            </>
                                        )}
                                    </>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
};

export default SList;
