import React from 'react';
import {useRecoilValue} from "recoil";
import {memberState} from "../../store/memberStore";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Backdrop, Box, Container} from "@mui/material";
import SHeader from "./SHeader";
import ANavbar from "./ANavbar";

const ALayout = () => {

    const memberInfo = useRecoilValue(memberState);
    const location = useLocation();
    const navigate = useNavigate();


    return (
        <>
            <Container sx={{height: '100%', backgroundColor: '#F4F5F7'}}>
                <Box sx={{height: '100%'}}>
                    <SHeader/>
                    <Container sx={{padding: 0, paddingTop: '50px', paddingBottom: '8vh', minHeight: '100vh'}}>
                        <Outlet/>
                    </Container>
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <ANavbar/>
                </Box>
            </Container>
        </>
    );
};

export default ALayout;
