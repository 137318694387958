import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import addIcon from "../../images/addbutton.png";
import {useRecoilValue} from "recoil";
import {memberState} from "../../store/memberStore";
import {colorState} from "../../store/colorStore";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "../../axiosInstance";

const EditItem = () => {

    const location = useLocation();
    const {data} = location.state || {};  // 전달된 데이터를 받음
    const color = useRecoilValue(colorState);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        productName: '',
        price: '',
        description: '',
        image: null,
        sCode: ''
    });
    const [selectedImage, setSelectedImage] = useState(addIcon);
    const memberInfo = useRecoilValue(memberState);

    useEffect(() => {
        setFormData({
            productName: data.ITEM_NAME,
            price: data.ITEM_PRICE,
            description: data.ITEM_DESC,
            image: data.ITEM_IMG,
            sCode: (data.S_CODE).toString(),
        })
    }, []);

    useEffect(() => {
        if (data) {
            setFormData({
                productName: data.ITEM_NAME,
                price: data.ITEM_PRICE,
                description: data.ITEM_DESC,
                image: data.ITEM_IMG,
                sCode: (data.S_CODE).toString(),
            });

            // 이미지 URL 설정
            const currentDomain = `${window.location.protocol}//${window.location.host}`;
            setSelectedImage(`${currentDomain}/files/${data.ITEM_IMG}`);
        }
    }, [data]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);  // 선택한 이미지의 URL 생성
            setSelectedImage(imageUrl);  // 선택한 이미지를 즉시 반영
            setFormData(prev => ({...prev, image: file}));
        }
    };

    const handleSubmit = async () => {
        const {productName, price, description, image, sCode,} = formData;
        // 유효성 검사

        const submissionData = new FormData();

        // 이미지가 변경되었을 경우에만 추가
        if (selectedImage !== imageURL(formData)) {
            submissionData.append('image', formData.image);
        }

        submissionData.append('productName', productName);
        submissionData.append('price', price);
        submissionData.append('description', description);
        submissionData.append('sCode', sCode);

        axios.patch(`/api/store/${memberInfo.memCode}`, submissionData, {
            headers: {'Content-Type': 'multipart/form-data'},
        })
            .then(() => {
                alert("상품이 성공적으로 수정되었습니다.");
                navigate('/item')

            })
            .catch((error) => {
                alert("상품 수정 중 오류가 발생했습니다.");
            });

    };

    const imageURL = ((formData) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${formData.image}`;
    });

    return (
        <>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Grid container justifyContent="center" sx={{
                    marginX: 2,
                    height: '82vh',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #e0e0e0',
                    borderRadius: 5,
                    padding: 1.5
                }}>
                    <Grid item xs={10} justifyContent="center">
                        <Box sx={{
                            backgroundColor: '#F4F5F7',
                            marginTop: 2,
                            borderRadius: 3,
                            width: '100%',
                            display: 'flex',
                            height: '28vh',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            padding: 2,
                        }} onClick={() => document.getElementById('fileInput').click()}>
                            <img style={{
                                width: '90%',
                                maxHeight: '100%',
                                objectFit: 'contain',
                                borderRadius: '8px'
                            }} src={selectedImage || imageURL(formData)}  alt="상품 이미지"/>
                        </Box>
                        <input id="fileInput" type="file" accept="image/*" style={{display: 'none'}}
                               onChange={handleImageChange}/>
                    </Grid>

                    <Grid item xs={5} display="flex" justifyContent="center">
                        <Button fullWidth
                                sx={{
                                    marginY: 1,
                                    height: '35px',
                                    backgroundColor: color,
                                    color: 'white',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    borderRadius: 3,
                                    '&:hover': {backgroundColor: '#999999'}
                                }}
                                onClick={() => document.getElementById('fileInput').click()}
                        >
                            사진 수정
                        </Button>
                    </Grid>

                    <Grid container spacing={2} justifyContent="center">
                        <Grid item container xs={10} spacing={1}>
                            <Grid item xs={3} display="flex" alignItems="center" justifyContent="end">
                                <Typography variant="body1" sx={{fontSize: '15px', color: '#777777'}}>상품명 :</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField size="small" fullWidth name="productName" value={formData.productName}
                                           onChange={handleInputChange} sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '30px',
                                        fontSize: '0.8rem',
                                        borderRadius: '8px',
                                        backgroundColor: '#F4F5F7'
                                    }, '& .MuiInputBase-input': {padding: '6px 8px'}
                                }}/>
                            </Grid>
                        </Grid>

                        <Grid item container xs={10} spacing={1}>
                            <Grid item xs={3} display="flex" alignItems="center" justifyContent="end">
                                <Typography variant="body1" sx={{fontSize: '15px', color: '#777777'}}>가격 :</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField size="small" fullWidth name="price" value={formData.price}
                                           onChange={handleInputChange} sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '30px',
                                        fontSize: '0.8rem',
                                        borderRadius: '8px',
                                        backgroundColor: '#F4F5F7'
                                    }, '& .MuiInputBase-input': {padding: '6px 8px'}
                                }}/>
                            </Grid>
                        </Grid>

                        <Grid item container xs={10} spacing={1}>
                            <Grid item xs={3} display="flex" alignItems="start" justifyContent="end">
                                <Typography variant="body1" sx={{paddingTop: 1, fontSize: '13px', color: '#777777'}}>
                                    상품 설명 :
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField size="small" multiline fullWidth rows={8} name="description"
                                           value={formData.description} onChange={handleInputChange} sx={{
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: '0.8rem',
                                        borderRadius: '8px',
                                        backgroundColor: '#F4F5F7'
                                    }
                                }}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={5} display="flex" justifyContent="center">
                            <Button fullWidth sx={{
                                marginTop: 0,
                                height: '35px',
                                backgroundColor: color,
                                color: 'white',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderRadius: 3,
                                '&:hover': {backgroundColor: '#999999'}
                            }} onClick={handleSubmit}>
                                수정 등록
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default EditItem;
