import React from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";

const RegInfo = ({setStep, setFormData, formData}) => {

    const color = useRecoilValue(colorState);


    const dTypeHandler = (payload) => {
        if (payload === '사업자') {
            setFormData({...formData, dType: '사업자'});
        } else if (payload === '비사업자') {
            setFormData({...formData, dType: '비사업자'});
        }
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Grid container spacing={1} display={'flex'} justifyContent="center"
                  sx={{backgroundColor: '#FFFFFF', marginX: 0.5, padding: 1, borderRadius: 3, height: '85vh'}}>
                <Grid item xs={12} sx={{height: '100px'}} display="flex" justifyContent="center" marginTop={1}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '40px',
                        height: '60px',
                    }}>
                        <Typography sx={{color: '#808080', fontSize: '17px'}}>
                            상점 정보가 없습니다.
                        </Typography>
                        <Typography sx={{color: '#808080', marginTop: '10px', fontSize: '17px'}}>
                            유형을 선택해주세요
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" gap={2} marginTop={0}>
                    {/* 사업자 카드 */}
                    <Box
                        sx={{
                            width: '150px',
                            height: '200px',
                            border: '1px solid #E0E0E0',  // 전체 보더 설정
                            borderRadius: '12px',
                            borderColor: formData.dType === '사업자' ? color : '#FFFFFF',
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        onClick={() => dTypeHandler('사업자')}
                    >
                        {/* 상단 영역 */}
                        <Box sx={{
                            height: '20%',
                            backgroundColor: formData.dType === '사업자' ? color : '#E0E0E0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography sx={{color: formData.dType === '사업자' ? '#FFFFFF' : '#808080', fontWeight: 'bold'}}>사업자</Typography>
                        </Box>
                        {/* 하단 내용 영역 */}
                        <Box sx={{padding: 1, display: 'flex', flexDirection: 'column'}}>
                            <Typography variant="body2" sx={{fontSize: '11px', height: '2rem'}} textAlign="start">
                                사업자 등록증을 소지하신 분
                            </Typography>
                            <Typography variant="body2" textAlign="start" marginTop={1}>
                                본 APP에서 <br/> 결제된 매출은 기존 사업자 번호로<br/> 국세청에 신고됩니다.
                            </Typography>
                        </Box>
                    </Box>

                    {/* 비사업자 카드 */}
                    <Box
                        sx={{
                            width: '150px',
                            height: '200px',
                            border: '1px solid #E0E0E0',  // 전체 보더 설정
                            borderRadius: '12px',
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: '#FFFFFF',
                            borderColor: formData.dType === '비사업자' ? color : '#FFFFFF',

                        }}
                        onClick={() => dTypeHandler('비사업자')}

                    >
                        {/* 상단 영역 */}
                        <Box sx={{
                            height: '20%',
                            backgroundColor: formData.dType === '비사업자' ? color : '#E0E0E0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography sx={{color: formData.dType === '비사업자' ? '#FFFFFF' : '#808080', fontWeight: 'bold'}}>비 사업자</Typography>
                        </Box>
                        {/* 하단 내용 영역 */}
                        <Box sx={{padding: 1, display: 'flex', flexDirection: 'column'}}>
                            <Typography variant="body2" sx={{fontSize: '12px', height: '2rem'}} textAlign="start">
                                사업자 등록증이 없는 분
                            </Typography>
                            <Typography variant="body2" textAlign="start" marginTop={1}>
                                본 APP에서 결제된 매출은 주민등록 번호로<br/>국세청에 신고됩니다.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                    <Button
                        variant="contained"
                        sx={{
                            width: '150px',
                            height: '40px',
                            marginTop: 2,
                            marginBottom: 2,
                            bgcolor: color,
                            color: '#FFFFFF',
                            fontSize: '18px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            '&:hover': {
                                bgcolor: '#501F73',
                            },
                        }}
                        onClick={() => setStep(2)}
                    >
                        다음
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RegInfo;
