import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Divider, TextField, Typography} from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {colorState} from "../../store/colorStore";
import {titleState} from "../../store/titleStore";
import {useNavigate} from "react-router-dom";
import {memberState} from "../../store/memberStore";
import axios from "axios";
const logo = `/images/${process.env.REACT_APP_LOGIN_IMG}`;
const logoIcon = `/images/${process.env.REACT_APP_ICON_IMG}`;

const SLogin = () => {

    const color = useRecoilValue(colorState);
    const title = useRecoilValue(titleState);
    const navigate = useNavigate();
    const setMemberInfo = useSetRecoilState(memberState);
    const [formData, setFormData] = useState({
        memId: "",
        memPw: "",
    });


    const handleChange = (e) => {
        const field = e.target.name;
        setFormData({...formData, [field]: e.target.value});
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
        axios.post('/api/auth/login', data).then((response) => {
            saveToken(response.data);
            setMemberInfo({
                memCode: response.data.memCode,
                memRole: response.data.memRole,
                memId: formData.memId,
                memName: response.data.memName,
                role: response.data.role,
                detail: response.data.detail,
            })
            navigate('/link');
        }).catch(err => {
            console.error(err)
            alert('아이디 혹은 비밀번호를 확인하세요')
        })
    }

    const saveToken = (data) => {
        document.cookie = `accessToken=${data.accessToken}; path=/;`;
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1); // 현재로부터 1년 후
        document.cookie = `refreshToken=${data.refreshToken}; path=/; expires=${expires.toUTCString()};`;
    }


    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
            } else {
            }
            setDeferredPrompt(null);
        }
    };


    return (
        <Container
            maxWidth="xs"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                bgcolor: '#F4F5F7',
                padding: '24px',
                paddingBottom: '40%',
            }}
        >
            {/* 로고 및 제목 */}
            <Box
                sx={{
                    textAlign: 'center',
                    marginBottom: 4,
                }}
            >
                <Typography variant="h6" sx={{color: color}} gutterBottom>
                    SMS 간편결제
                </Typography>
                <img width={'220px'} src={logo} alt={'logo'}/>

            </Box>

            {/* 로그인 폼 */}
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    padding: 5,
                }}
            >
                <TextField
                    label="아이디"
                    fullWidth
                    onChange={handleChange}
                    name={'memId'}
                    id={'memId'}
                    margin="normal"
                    variant="outlined"
                    size={'small'}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',  // border-radius 설정
                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                        }
                    }}
                />
                <TextField
                    label="비밀번호"
                    onChange={handleChange}
                    type="password"
                    name="memPw"
                    id="memPw"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size={'small'}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',  // border-radius 설정.
                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                        }
                    }}
                />
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        bgcolor: color,
                        color: '#FFFFFF',
                        borderRadius: 3,
                        '&:hover': {
                            bgcolor: '#501F73',
                        },
                    }}
                >
                    로 그 인
                </Button>

                {/* 계정 찾기 및 비밀번호 재설정 링크 */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        marginBottom: 2,
                        marginY: 3,
                    }}
                >
                    <Typography
                        variant='body2'
                        sx={{
                            color: '#5f5f5f',
                            fontSize: '12px',
                        }}
                        >
                        계정 찾기
                    </Typography>
                    <Typography
                        variant='body2'
                        sx={{
                            color: '#5f5f5f',
                            fontSize: '12px',
                        }}
                    >
                        비밀번호 재설정
                    </Typography>

                </Box>

                <Divider
                    sx={{
                        marginBottom: 2,
                        borderWidth: '1px',  // 굵기 설정
                        borderColor: '#5f5f5f',  // 색상 설정
                    }}
                />
                <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                        marginTop: 3,
                        borderColor: color,
                        backgroundColor: '#FFFFFF',
                        color: color,
                        borderRadius: 3,
                    }}
                    onClick={() => navigate('/join')}
                >
                    회원가입
                </Button>
            </Box>
            <Box
                sx={{
                    position: 'absolute',  // 절대 위치
                    bottom: 0,             // 화면 하단에 고정
                    left: 0,
                    right: 0,
                    margin: '0 15px',       // 좌우 마진 5px
                    border: '1px solid '+ color,  // 상단 보더 설정
                    borderRadius: '12px 12px 0 0', // 상단 좌우 라운드 12px
                    padding: '16px 0',     // 상단/하단 패딩
                    textAlign: 'center',
                    backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                    display: 'flex',
                    alignItems: 'center',  // 세로 중앙 정렬
                    justifyContent: 'center',  // 가로 중앙 정렬
                    marginTop: 4,
                }}
                onClick={handleInstallClick}
            >
                <img
                    src={logoIcon}  // 적절한 앱 로고 이미지 경로
                    alt="App Logo"
                    style={{ marginBottom: '1px' }}
                />
                <Typography variant="body1">
                    {title + ' APP'}
                    <br />
                    다운로드
                </Typography>
            </Box>
        </Container>
    );
};

export default SLogin;
