import React from 'react';
import {Button, Grid, Typography} from "@mui/material";
import profile from "../../images/profile.png"
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import {useNavigate} from "react-router-dom";

const PayComp = ({linkData, shopData}) => {
    const color = useRecoilValue(colorState);
    const navigate = useNavigate();

    return (
        <>
            <Grid item container xs={11}
                  sx={{
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #e0e0e0',
                      borderRadius: 5,
                      padding: 1,
                  }}
                  display={'flex'}
                  justifyContent={'center'}
            >
                <Grid item xs={11}
                      display={'flex'}
                      justifyContent={'center'}

                >
                    <img src={profile} style={{width: '120px'}} alt={'프로필 이미지'}/>
                </Grid>
                <Grid item xs={7}
                      display={'flex'}
                      justifyContent={'center'}
                      flexDirection={'column'}
                >
                    <Typography variant="body1" sx={{fontSize: '14px', textAlign: 'center', marginTop: 2}}>
                        {'상점명 : ' + shopData.memName}
                    </Typography>
                    <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold', marginY: 2}}>
                        {parseInt(linkData.PRICE).toLocaleString() + ' 원'}
                    </Typography>
                    <Typography variant="h6" sx={{fontSize: '21px', textAlign: 'center'}}>
                        {'결제 완료'}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                item
                container
                spacing={1}
                xs={11}
                sx={{
                    position: 'absolute',  // 절대 위치 사용
                    bottom: '0vh',  // 하단에서 8vh 위에 위치
                    backgroundColor: '#D4D4D4',
                    borderTopLeftRadius: '16px',  // 상단 좌측 모서리 라운드 처리
                    borderTopRightRadius: '16px',  // 상단 우측 모서리 라운드 처리
                    overflow: 'hidden',  // 자식 요소가 부모 요소를 넘지 않도록 설정
                }}
                display={'flex'}
                justifyContent={'center'}  // 수평 중앙 정렬
                alignItems={'start'}  // 수직 중앙 정렬
            >
                <Grid item xs={12}
                      display={'flex'}
                      justifyContent={''}
                      flexDirection={'column'}
                >
                    <Typography variant="body1"
                                sx={{fontSize: '0.8rem', height: '40px', color: '#757575', marginLeft: 2, padding: 0.5, paddingTop: 1}}>
                        비대면 모바일 간편 결제 솔루션 사용을 원하시는 분은 <br/>
                        하단의 상점 개설 신청하기 버튼을 눌러주세요.
                    </Typography>
                </Grid>
                <Grid item xs={12}
                      display={'flex'}
                      justifyContent={'center'}  // 수평 중앙 정렬
                >
                    <Button
                        fullWidth
                        sx={{
                            marginX: 1,
                            marginY: 1,
                            paddingY: 2,
                            height: '90px',
                            backgroundColor: color,
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            borderRadius: 10,
                            '&:hover': {backgroundColor: '#999999'}
                        }}
                        onClick={() => (navigate('/join'))}
                    >
                        모바일 간편결제 <br/>
                        상점 개설 신청하기
                    </Button>

                </Grid>
            </Grid>
        </>
    );
};

export default PayComp;
