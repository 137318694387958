import React, {useEffect, useState} from 'react';
import {Box, Button, Container, TextField, Typography} from "@mui/material";
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const logo = `/images/${process.env.REACT_APP_LOGIN_IMG}`;

const SJoin = () => {

    const navigate = useNavigate();
    const color = useRecoilValue(colorState);
    const [idCheck, setIdCheck] = useState(false);
    const [formData, setFormData] = useState({
        memId: "",
        memPw: "",
        memName: "",
        memPwCheck: "",
        memTell: ""
    });


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                handleSubmit();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const checkDuplicateId = () => {

        if(!formData.memId || formData.memId === "") {
            alert("아이디를 입력해주세요.");
            return;
        }

        axios.get(`/api/auth/checkId/${formData.memId}`).then(response => {
            setIdCheck(!response.data);
            !response.data ? alert("사용가능한 아이디 입니다.") : alert("이미 사용중인 아이디 입니다.")
        }).catch(err => {
            console.error(err);
        });

    }

    const handleChange = (e) => {
        const field = e.target.name;
        setFormData({...formData, [field]: e.target.value});
    };

    const handleSubmit = (event) => {
        event.preventDefault()

        if (!idCheck) {
            alert("아이디 중복검사를 해주세요.");
            return;
        }

        if(formData.memPw !== formData.memPwCheck){
            alert('비밀번호가 일치하지 않습니다')
            return;
        }


        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }


        axios.post('/api/auth/join', data).then((response) => {
            alert('회원가입이 완료되었습니다')
            navigate('/login');

        }).catch(err => {
            alert('회원가입중 오류가 발생했습니다.')
        })
    }

    return (
        <Container
            sx={{
                minHeight: '100vh',
                bgcolor: '#F4F5F7',
            }}
        >
            <Box
                sx={{
                    padding: 1,
                }}
            >
                <img width={'170px'} src={logo} alt={'logo'}/>
            </Box>
            <Box
                sx={{
                    padding: 1,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="body1" sx={{color: color, marginTop: 5, fontWeight: 'bold'}} gutterBottom>
                    GGSHOP 가입신청
                </Typography>
            </Box>
            <Box
                onSubmit={handleSubmit}
                component="form"
                sx={{
                    padding: 1,
                    paddingX: 3,
                }}
            >


                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1, // 텍스트 필드와 버튼 간격
                    }}
                >

                    <TextField
                        label="아이디"
                        onChange={handleChange}
                        name={'memId'}
                        id={'memId'}
                        margin="normal"
                        variant="outlined"
                        size={'small'}
                        required={true}
                        sx={{
                            flex: 7,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '5px',  // border-radius 설정
                                backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        sx={{
                            marginTop: 1,
                            flex: 3,
                            bgcolor: color,
                            color: '#FFFFFF',
                            fontSize: '12px',
                            borderRadius: '4px',
                            fontWeight: 'bold',
                            height: '38px', // 버튼 높이를 텍스트 필드와 맞춤
                            minWidth: 'auto', // 내용에 맞게 버튼 크기 조정
                            '&:hover': {
                                bgcolor: '#501F73',
                            },
                        }}
                        onClick={checkDuplicateId}
                    >
                        중복확인
                    </Button>
                </Box>



                <TextField
                    label="비밀번호"
                    onChange={handleChange}
                    type="password"
                    name="memPw"
                    id="memPw"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size={'small'}
                    required={true}

                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '5px',  // border-radius 설정.
                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                        }
                    }}
                />
                <TextField
                    label="비밀번호 확인"
                    onChange={handleChange}
                    type="password"
                    name="memPwCheck"
                    id="memPwCheck"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size={'small'}
                    required={true}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '5px',  // border-radius 설정.
                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                        }
                    }}
                />
                <TextField
                    label="이름"
                    onChange={handleChange}
                    type="text"
                    name="memName"
                    id="memName"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    required={true}
                    size={'small'}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '5px',  // border-radius 설정.
                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                        }
                    }}
                />
                {/* 휴대폰 번호와 인증 버튼 */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1, // 텍스트 필드와 버튼 간격
                    }}
                >
                    <TextField
                        label="휴대폰 번호"
                        onChange={handleChange}
                        type="text"
                        name="memTell"
                        id="memTell"
                        required
                        fullWidth
                        sx={{
                            flex: 9, // 70% 비율
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '5px',
                                backgroundColor: '#FFFFFF',
                            }
                        }}
                        size="small"
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        sx={{
                            marginTop: 1,
                            flex: 1, // 30% 비율
                            bgcolor: color,
                            color: '#FFFFFF',
                            fontSize: '12px',
                            borderRadius: '4px',
                            fontWeight: 'bold',
                            height: '38px', // 버튼 높이를 텍스트 필드와 맞춤
                            minWidth: 'auto', // 내용에 맞게 버튼 크기 조정
                            '&:hover': {
                                bgcolor: '#501F73',
                            },
                        }}
                    >
                        인증
                    </Button>
                </Box>
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        bgcolor: color,
                        color: '#FFFFFF',
                        fontSize: '18px',
                        borderRadius: '24px',
                        fontWeight: 'bold',
                        '&:hover': {
                            bgcolor: '#501F73',
                        },
                    }}
                >
                    회원가입
                </Button>
            </Box>
        </Container>
    );
};

export default SJoin;
