import React, {useEffect, useState} from 'react';
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import axios from "../../axiosInstance";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import {memberState} from "../../store/memberStore";

const SChangePw = () => {

    const [changePw, setChangePw] = useState({oldPassword: '', newPassword: '', confirmPassword: ''});
    const color = useRecoilValue(colorState);


    const handlePasswordChange = () => {
        if (changePw.newPassword !== changePw.confirmPassword) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }
        // 예: API 호출 등

        axios.patch(`/api/member/changePw`, changePw).then((response) => {
            setChangePw({oldPassword: '', newPassword: '', confirmPassword: ''});
            alert('비밀번호가 변경되었습니다.');
        }).catch((error) => {
            alert('비밀번호 변경에 실패했습니다.');
        });

    };
    const handlePwChange = (e) => {
        setChangePw({...changePw, [e.target.name]: e.target.value});
    };

    return (
        <>
            <Grid container spacing={1} sx={{ paddingTop: 10 }} display={'flex'} justifyContent="center">
                {/*<Typography*/}
                {/*    variant="h5"*/}
                {/*    style={{*/}
                {/*        borderBottom: "2px solid black",*/}
                {/*        marginBottom: "5px",*/}
                {/*    }}*/}
                {/*>*/}
                {/*    비밀번호 변경*/}
                {/*</Typography>*/}


                <Grid item xs={4} style={{paddingTop: "0px", marginBottom: "20px",}}>
                    <Typography variant="body2"
                                sx={{marginY: 1.2, height: '30px', fontSize: '1rem', color: '#A0A0A0', paddingY: 0.5}}>
                        {'기존 비밀번호 : '}
                    </Typography>
                    <Typography variant="body2"
                                sx={{marginY: 1.8, height: '30px', fontSize: '1rem', color: '#A0A0A0', paddingY: 0.5}}>
                        {'새 비밀번호 : '}
                    </Typography>
                    <Typography variant="body2"
                                sx={{marginY: 2, height: '30px', fontSize: '1rem', color: '#A0A0A0', paddingY: 0.5}}>
                        {'비밀번호 확인 : '}
                    </Typography>
                </Grid>

                <Grid item xs={6} style={{paddingTop: "0px", marginBottom: "20px",}}>
                    <TextField
                        name="oldPassword"
                        value={changePw.oldPassword}
                        onChange={handlePwChange}
                        type="password"
                        size={'small'}
                        sx={{
                            marginY: 1,
                            '& .MuiOutlinedInput-root': {
                                height: '30px',
                                fontSize: '0.8rem',
                                borderRadius: '8px',
                                backgroundColor: '#F4F5F7'
                            }, '& .MuiInputBase-input': {padding: '6px 8px'}
                        }}
                    />
                    <TextField
                        name="newPassword"
                        value={changePw.newPassword}
                        onChange={handlePwChange}
                        type="password"
                        sx={{
                            marginY: 1,
                            '& .MuiOutlinedInput-root': {
                                height: '30px',
                                fontSize: '0.8rem',
                                borderRadius: '8px',
                                backgroundColor: '#F4F5F7'
                            }, '& .MuiInputBase-input': {padding: '6px 8px'}
                        }}
                        size={'small'}
                    />
                    <TextField
                        name="confirmPassword"
                        value={changePw.confirmPassword}
                        onChange={handlePwChange}
                        type="password"
                        sx={{
                            marginY: 1,
                            '& .MuiOutlinedInput-root': {
                                height: '30px',
                                fontSize: '0.8rem',
                                borderRadius: '8px',
                                backgroundColor: '#F4F5F7'
                            }, '& .MuiInputBase-input': {padding: '6px 8px'}
                        }}
                        size={'small'}
                    />
                </Grid>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: color,
                        borderRadius: 3
                    }}
                    onClick={handlePasswordChange}
                >
                    비밀번호 변경
                </Button>
            </Grid>
        </>
    );
};

export default SChangePw;
