import React, {useEffect} from 'react';
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {useNavigate} from "react-router-dom";
import cardImg from '../../images/card.png'
import itemImg from '../../images/item.png'
import myshopImg from '../../images/myshop.png'
import paylistImg from '../../images/paylist.png'
import cardwImg from '../../images/cardw.png'
import itemwImg from '../../images/itemw.png'
import myshopwImg from '../../images/myshopw.png'
import paylistwImg from '../../images/paylistw.png'
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";

const SNavbar = () => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState('/link');
    const color = useRecoilValue(colorState);

    useEffect(() => {
        handleChange()
        pathHandler();
    }, [value]);
    const handleChange = () => {
        setValue(value);
    };

    const pathHandler = () => {
        navigate(value);
    };

    return (
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            sx={{
                width: '100%',
                height: '8vh',
                position: 'fixed',
                bottom: 0,
                backgroundColor: '#fff', // 하단 고정, 배경색 흰색
                boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.24)', // 상단에 그림자 추가
                zIndex: 9999
            }} // 하단 고정, 배경색 흰색
        >
            <BottomNavigationAction
                label="결제 링크"
                value={'/link'}
                icon={
                    <img
                        src={value === '/link' ? cardwImg : cardImg}
                        alt="icon"
                        style={{ width: 22, height: 22, marginBottom: 10, marginTop: 2 }}
                    />
                }
                sx={{
                    '& .MuiBottomNavigationAction-label': {
                        fontSize: '12px',  // 글자 크기
                    },
                    '&.Mui-selected': {color: 'white', fontWeight: 'bold', backgroundColor: color }, // 선택된 아이템의 색상을 회색으로 설정
                    color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                }}
            />
            <BottomNavigationAction
                label="등록 상품"
                value={'/item'}
                icon={
                    <img
                        src={value === '/item' ? itemwImg : itemImg}
                        alt="icon"
                        style={{ width: 22, height: 22, marginBottom: 10, marginTop: 2 }}
                    />
                }
                sx={{
                    '& .MuiBottomNavigationAction-label': {
                        fontSize: '12px',  // 글자 크기
                    },
                    '&.Mui-selected': {color: 'white', fontWeight: 'bold', backgroundColor: color }, // 선택된 아이템의 색상을 회색으로 설정
                    color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                }}
            />
            <BottomNavigationAction
                label="결제 목록"
                value={'/list'}
                icon={
                    <img
                        src={value === '/list' ? paylistwImg : paylistImg}
                        alt="icon"
                        style={{ width: 22, height: 22, marginBottom: 10, marginTop: 2 }}
                    />
                }
                sx={{
                    '& .MuiBottomNavigationAction-label': {
                        fontSize: '12px',  // 글자 크기
                    },
                    '&.Mui-selected': {color: 'white', fontWeight: 'bold', backgroundColor: color }, // 선택된 아이템의 색상을 회색으로 설정
                    color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                }}
            />
            <BottomNavigationAction
                label="내 상점 "
                value={'/myshop'}
                icon={
                    <img
                        src={value === '/myshop' ? myshopwImg : myshopImg}
                        alt="icon"
                        style={{ width: 22, height: 22, marginBottom: 10, marginTop: 2 }}
                    />
                }
                sx={{
                    '& .MuiBottomNavigationAction-label': {
                        fontSize: '12px',  // 글자 크기
                    },
                    '&.Mui-selected': {color: 'white', fontWeight: 'bold', backgroundColor: color }, // 선택된 아이템의 색상을 회색으로 설정
                    color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                }}
            />
        </BottomNavigation>
    );
};

export default SNavbar;
