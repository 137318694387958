import React from 'react';
import Modal from "@mui/material/Modal";
import {Box, Button, Typography} from "@mui/material";
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    // height: '37vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 12,
    p: 2,
};
const buttonStyle = {
    width: '90px', // 버튼 너비
    height: '75px', // 버튼 높이
    margin: '5px', // 버튼 간격
    color: 'white',
    borderRadius: 2,
    padding: 1
};
const UrlModal = ({open, setOpen, onConfirm}) => {

    const handleClose = () => setOpen(false);
    const color = useRecoilValue(colorState);
    const handleConfirm = () => {
        onConfirm(); // 버튼 클릭 시 부모 컴포넌트의 확인 함수 호출
        setOpen(false); // 모달 닫기
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" sx={{fontSize: '15px', textAlign: 'center'}} variant="h6"
                                component="h2">
                        수령확인 URL 만들기는 <br/>
                        유료 서비스입니다. <br/>
                        매출 결제 금액 입금시 <br/>
                        300원이 차감됩니다. <br/>
                    </Typography>
                    <Box display="flex" justifyContent="center" marginTop={2}>
                        <Button onClick={handleClose} variant="contained" sx={{...buttonStyle, backgroundColor: 'gray'}}>
                            취 소
                        </Button>
                        <Button onClick={handleConfirm} variant="contained" sx={{...buttonStyle, backgroundColor: color}}>
                            URL 만들기
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default UrlModal;
