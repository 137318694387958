import React, {useEffect, useState} from 'react';
import {Box, Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import SHeader from "../layout/SHeader";
import PayComp from "./PayComp";
import PayPage from "./PayPage";
import axios from "axios";

const PayUrl = () => {
    const [itemList, setItemList] = useState([])
    const [shopData, setShopData] = useState({})
    const [linkData, setLinkData] = useState({})

    const {link} = useParams();
    const [isComp, setIsComp] = useState(false)

    useEffect(() => {
        getData();
    }, [link]);

    const getData = () => {
        axios.get(`/api/auth/payLink/${link}`).then(res => {
            setItemList(res.data.linkDetailData);
            setShopData(res.data.member);
            setLinkData(res.data.linkData);

        })
    }
    return (
        <Box sx={{backgroundColor: '#F4F5F7', width: '100vw', height: '100vh'}}>
            <SHeader btnState={'hide'}></SHeader>
            <Grid container display={'flex'} justifyContent={'center'} sx={{ paddingTop: 6 }}>
                {isComp ?
                    <PayComp shopData={shopData} linkData={linkData}/>
                    :
                    <PayPage isPayPage={true} link={link} setIsComp={setIsComp} itemList={itemList} shopData={shopData} linkData={linkData}/>
                }
            </Grid>
        </Box>
    );
};

export default PayUrl;
