import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, IconButton, TextField, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";

const ItemDetail = ({data, setItemList, setIsDetail, setIsSelect}) => {

    const [quantity, setQuantity] = useState(1);
    const color = useRecoilValue(colorState);

    useEffect(() => {
    }, []);

    const selectHandler = () => {
        const newData = {
            ...data,
            quantity: quantity
        }
        setItemList(prev => [...prev, newData]);
        setIsDetail(false);
        setIsSelect(false);
    }


    const handleIncrement = () => {
        setQuantity(prev => prev + 1);
    };

    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(prev => prev - 1);
        }
    };

    const handleChange = (event) => {
        const value = parseInt(event.target.value);
        if (value >= 1) setQuantity(value);
    };

    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.ITEM_IMG}`;
    });
    return (
        <>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Grid container spacing={2} display={'flex'} justifyContent="center"
                      sx={{backgroundColor: '#FFFFFF', margin: 2, padding: 1, borderRadius: 3}}>
                    <Grid item xs={10}>
                        <Box sx={{
                            backgroundColor: '#F4F5F7',
                            marginTop: 2,
                            borderRadius: 3,
                            width: '100%',
                            display: 'flex',
                            height: '20vh',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            padding: 2,
                        }}>
                            <img style={{
                                width: '90%',
                                maxHeight: '100%',
                                borderRadius: '8px'
                            }} src={imageURL(data)} alt="add icon"/>

                        </Box>
                    </Grid>
                    <Grid item container xs={10} spacing={1}>
                        <Grid item xs={5} display="flex" alignItems="center" justifyContent="end">
                            <Typography variant="body1" sx={{fontSize: '13px', color: '#777777'}}>상품명 :</Typography>
                        </Grid>
                        <Grid item xs={7} display="flex" alignItems="center" justifyContent="start">
                            <Typography variant="body1" sx={{fontSize: '15px'}}>
                                {data.ITEM_NAME}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container xs={10} spacing={1}>
                        <Grid item xs={5} display="flex" alignItems="center" justifyContent="end">
                            <Typography variant="body1" sx={{fontSize: '13px', color: '#777777'}}>가 격 :</Typography>
                        </Grid>
                        <Grid item xs={7} display="flex" alignItems="center" justifyContent="start">
                            <Typography variant="body1" sx={{fontSize: '15px'}}>
                                {parseInt(data.ITEM_PRICE).toLocaleString() + '원'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={10} spacing={1}>
                        <Grid item xs={5} display="flex" alignItems="start" justifyContent="end">
                            <Typography variant="body1" sx={{fontSize: '13px', color: '#777777'}}>
                                상품 설명 :
                            </Typography>
                        </Grid>
                        <Grid item xs={7} display="flex" alignItems="center" justifyContent="start">
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '15px',
                                    minHeight: '23vh',
                                    maxWidth: '40vw',  // 화면의 40% 너비로 제한
                                    wordWrap: 'break-word',  // 단어가 넘칠 때 줄바꿈
                                    overflowWrap: 'break-word',  // 텍스트가 부모 영역을 넘을 때 줄바꿈
                                    whiteSpace: 'normal',  // 일반적인 줄바꿈 허용
                                }}
                            >
                                {data.ITEM_DESC}
                                {/*{'dsalkfjaslkjdlaksjdflkajsdlfkjaslkdjlaksjdflkasjdflkjaslkdfjalskdjlkasjdflkajsdflkjasldfkjlaskdjfglaskjdlask'}*/}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={10} spacing={1}>
                        <Grid item xs={5} display="flex" alignItems="start" justifyContent="end">
                            <Typography variant="body1" sx={{paddingTop: 1, fontSize: '13px', color: '#777777'}}>
                                수량 :
                            </Typography>
                        </Grid>
                        <Grid item xs={7} display="flex" alignItems="center" justifyContent="start">

                            <Box display="flex" alignItems="center">
                                <IconButton
                                    onClick={handleDecrement}
                                    sx={{
                                        borderRadius: '8px',
                                        marginRight: 1
                                    }}
                                >
                                    <RemoveIcon fontSize="small"/>
                                </IconButton>

                                <TextField
                                    size="small"
                                    value={quantity}
                                    disabled={true}
                                    onChange={handleChange}
                                    sx={{
                                        width: '45px',
                                        '& .MuiOutlinedInput-root': {
                                            fontSize: '0.8rem',
                                            borderRadius: '8px',
                                            backgroundColor: '#FFFFFF',
                                            '&.Mui-disabled': {
                                                color: 'black',  // Change text color to black when disabled
                                            },
                                        },
                                    }}
                                />
                                <IconButton
                                    onClick={handleIncrement}
                                    sx={{
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '8px',
                                        marginLeft: 1
                                    }}
                                >
                                    <AddIcon fontSize="small"/>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    xs={11}
                    sx={{
                        position: 'absolute',  // 절대 위치 사용
                        bottom: '8vh',  // 하단에서 8vh 위에 위치
                        backgroundColor: '#dddddd',
                        borderTopLeftRadius: '16px',  // 상단 좌측 모서리 라운드 처리
                        borderTopRightRadius: '16px',  // 상단 우측 모서리 라운드 처리
                        overflow: 'hidden',  // 자식 요소가 부모 요소를 넘지 않도록 설정
                    }}
                    display={'flex'}
                    justifyContent={'center'}  // 수평 중앙 정렬
                    alignItems={'center'}  // 수직 중앙 정렬
                >
                    <Grid item xs={8}
                          display={'flex'}
                          justifyContent={'center'}
                          flexDirection={'column'}
                          sx={{paddingLeft: 3}}
                    >
                        <Typography variant="body2" sx={{fontSize: '0.6rem', color: '#777777'}}>
                            수량 입력 확인 후 우측
                        </Typography>
                        <Typography variant="body2" sx={{fontSize: '0.6rem', color: '#777777'}}>
                            결제상품 추가하기 버튼을 눌러주세요.
                        </Typography>
                        <Typography variant="body1" sx={{marginTop: 0.5, color: '#777777'}}>
                            {' 합 계 : '}
                            <span style={{fontWeight: 'bold', fontSize: '20px', color: 'black'}}>
                                {(data.ITEM_PRICE * quantity).toLocaleString() + ' 원'}
                            </span>
                        </Typography>

                    </Grid>
                    <Grid item xs={4}
                          display={'flex'}
                          justifyContent={'center'}  // 수평 중앙 정렬
                    >
                        <Button
                            sx={{
                                marginX: 2,
                                marginY: 1,
                                paddingY: 2,
                                height: '65px',
                                backgroundColor: color,
                                color: 'white',
                                fontSize: '11px',
                                borderRadius: 10,
                                lineHeight: '1.2',
                                '&:hover': {backgroundColor: '#999999'}
                            }}
                            onClick={selectHandler}
                        >
                            결제 상품 <br/>
                            추가하기
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ItemDetail;
