import React, {useEffect, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import profile from "../../images/profile.png";
import PayInfo from "./PayInfo";
import ConfirmInfo from "./ConfirmInfo";

const PayPage = ({linkData, shopData, itemList, setIsComp, linkUrl, isPayPage}) => {


    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.ITEM_IMG}`;
    });


    return (
        <>
            <Grid item container xs={11}
                  sx={{
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #e0e0e0',
                      borderRadius: 5,
                      padding: 1,
                  }}
            >
                <Grid item xs={5}>
                    <img src={profile} style={{ width: '90px' }} alt={'프로필 이미지'} />
                </Grid>
                <Grid item xs={2}
                >
                    <Typography variant="body1" sx={{fontSize: '12px'}}>
                        {'상점명 : '}
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '12px'}}>
                        {'대표 : '}
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '12px'}}>
                        {'연락처 : '}
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '12px'}}>
                        {'주 소 : '}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1" sx={{fontSize: '12px'}}>
                        {shopData.memName}
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '12px'}}>
                        {shopData?.shopOwner}
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '12px'}}>
                        {shopData?.shopTell}
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '12px'}}>
                        {shopData?.shopAddr2}
                    </Typography>
                </Grid>
            </Grid>
            <Typography variant={'body1'} sx={{fontSize: '17px'}}>
                구매 상품
            </Typography>
            {itemList.length > 0 &&
                itemList.map((item, index) => (
                    <>

                        <Grid item container xs={11} key={index}
                              sx={{
                                  backgroundColor: '#FFFFFF',
                                  border: '1px solid #e0e0e0',
                                  borderRadius: 5,
                                  padding: 1,
                              }}
                        >
                            <Grid item xs={4}>
                                <img
                                    style={{
                                        borderRadius: '12px',
                                        width: '80px',
                                        height: '75px',
                                        objectFit: 'cover', // 이미지가 부모 영역에 꽉 차게 설정
                                    }}
                                    src={imageURL(item)} alt="add image"/>
                            </Grid>
                            <Grid item xs={2}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{textAlign: 'right'}} // 우측 정렬 설정
                            >
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {'상품명 : '}
                                </Typography>
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {'가  격 : '}
                                </Typography>
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {'수  량 : '}
                                </Typography>
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {'설  명 : '}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{paddingLeft: 1}}
                            >
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {item.ITEM_NAME}
                                </Typography>
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {(item.ITEM_PRICE * item.COUNT).toLocaleString() + '원'}
                                </Typography>
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {item.COUNT}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: '0.8rem',
                                        overflow: 'hidden',       // 넘치는 내용을 숨김
                                        whiteSpace: 'nowrap',     // 한 줄로 표시
                                        textOverflow: 'ellipsis',  // 넘치는 텍스트를 ellipsis(...)로 표시
                                    }}
                                >
                                    {item.ITEM_DESC}
                                </Typography>
                            </Grid>
                        </Grid>

                    </>
                ))}
            {isPayPage ?
                <PayInfo linkData={linkData} setIsComp={setIsComp} linkUrl={linkUrl}/>
                :
                <ConfirmInfo linkData={linkData} setIsComp={setIsComp} linkUrl={linkUrl}/>
            }

        </>
    );
};

export default PayPage;
