import React, {useEffect, useState} from 'react';
import {Button, Grid, TextField, Typography} from "@mui/material";
import addImg from "../../images/addbutton.png";
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import axios from "../../axiosInstance";
import {memberState} from "../../store/memberStore";
import OverModal from "./OverModal";
import {useNavigate} from "react-router-dom";

const SelectedList = ({setIsSelect, itemList}) => {

    const memberInfo = useRecoilValue(memberState);
    const color = useRecoilValue(colorState);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isSet, setIsSet] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        tell: ''
    });
    const [isComp, setIsComp] = useState(false)
    const [url, setUrl] = useState('https://www.ghpay.kr/link');
    const [overModal, setOverModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
    }, []);


    useEffect(() => {
        let total = 0;
        itemList.map((item) => {
            total += item.ITEM_PRICE * item.quantity;
        });
        setTotalPrice(total);
    }, [itemList]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;

        if (name === 'name') {
            // 한글, 영어만 허용하며, 최대 12자리까지 입력 가능
            setFormData(prev => ({...prev, [name]: value}));
        }

        if (name === 'tell') {
            // 숫자만 허용하며, 최대 11자리까지 입력 가능
            const regex = /^[0-9]{0,11}$/;
            if (!regex.test(value)) {
                alert('최대 11자리 숫자만 입력 ( - 제외 )')
                setFormData(prev => ({...prev, [name]: ''})); // 조건에 맞지 않으면 내용 삭제
            } else {
                setFormData(prev => ({...prev, [name]: value}));
            }
        }

    };

    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.ITEM_IMG}`;
    });

    const checkPrice = () => {

        if (totalPrice > 1000000) {
            setOverModal(true);
            return
        }

        handleSumit();
    }

    const handleSumit = () => {
        const payload = {
            name: formData.name,
            tell: formData.tell,
            itemList: itemList,
            memCode: memberInfo.memCode
        }
        axios.post(`/api/store/link`, payload).then((res) => {
            alert('결제링크가 생성되었습니다.')
            setIsComp(true);
            setUrl(`https://www.ggshop.kr/link/${res.data}`)
        }).catch(() => {
        });
    }

    const handleShare = async () => {
        // URL이 제공되지 않으면 함수 종료
        if (!url) {
            console.error('공유할 URL이 제공되지 않았습니다.');
            return;
        }

        // navigator.share를 지원하는지 확인
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'GHPAY 개인 결제', // 필요한 경우 제목 변경
                    url: url, // 공유할 URL
                });
                console.log('공유가 완료되었습니다.');
            } catch (error) {
                console.error('공유 실패:', error);
            }
        } else {
            // navigator.share를 지원하지 않는 경우
            console.log('이 브라우저는 공유 기능을 지원하지 않습니다.');
            // URL을 클립보드에 복사
            try {
                await navigator.clipboard.writeText(url);
                alert('URL이 클립보드에 복사되었습니다.');
            } catch (copyError) {
                console.error('클립보드 복사 실패:', copyError);
            }
        }
        navigate('/linkList')
    };


    return (
        <>
            {(isSet || isComp) && (
                <Typography variant={'body1'} sx={{fontSize: '17px'}}>
                    구매 상품
                </Typography>
            )}
            {itemList.length !== 0 &&
                itemList.map((item, index) => (
                    <>

                        <Grid item container xs={11} key={index}
                              sx={{
                                  backgroundColor: '#FFFFFF',
                                  border: '1px solid #e0e0e0',
                                  borderRadius: 5,
                                  padding: 1,
                              }}
                        >
                            <Grid item xs={4}>
                                <img
                                    style={{
                                        borderRadius: '12px',
                                        width: '80px',
                                        height: '75px',
                                        objectFit: 'cover', // 이미지가 부모 영역에 꽉 차게 설정
                                    }}
                                    src={imageURL(item)} alt="add image"/>
                            </Grid>
                            <Grid item xs={2}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{textAlign: 'right'}} // 우측 정렬 설정
                            >
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {'상품명 : '}
                                </Typography>
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {'가  격 : '}
                                </Typography>
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {'수  량 : '}
                                </Typography>
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {'설  명 : '}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{paddingLeft: 1}}
                            >
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {item.ITEM_NAME}
                                </Typography>
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {(item.ITEM_PRICE * item.quantity).toLocaleString() + '원'}
                                </Typography>
                                <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                    {item.quantity}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: '0.8rem',
                                        overflow: 'hidden',       // 넘치는 내용을 숨김
                                        whiteSpace: 'nowrap',     // 한 줄로 표시
                                        textOverflow: 'ellipsis',  // 넘치는 텍스트를 ellipsis(...)로 표시
                                    }}
                                >
                                    {item.ITEM_DESC}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                ))}
            {(!isSet && !isComp) &&
                <Grid item xs={10} sx={{marginTop: 2, paddingBottom: '5vh'}} display={'flex'} justifyContent={'center'}>
                    <img
                        style={{
                            borderRadius: '12px',
                            width: '15%',
                            height: '100%',
                        }}
                        src={addImg} alt="add image"
                        onClick={() => setIsSelect(true)}
                    />
                </Grid>
            }


            {(isSet || isComp) &&
                <Grid
                    container
                    xs={12}
                    sx={{
                        position: 'absolute',  // 절대 위치 사용
                        bottom: '18vh',  // 하단에서 20vh 위에 위치
                        backgroundColor: '#FFFFFF',
                        borderRadius: 5,
                        overflow: 'hidden',  // 자식 요소가 부모 요소를 넘지 않도록 설정
                        zIndex: 9999,
                        paddingTop: 0.5
                    }}
                >

                        <Grid
                            item
                            xs={3}
                            sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body1"
                                        sx={{fontSize: '0.7rem', height: '30px', paddingTop: 1}}>
                                {'구매자 명 : '}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            sx={{textAlign: 'left'}} // 왼쪽 정렬로 수정
                        >
                            <TextField
                                size="small"
                                fullWidth
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                disabled={isComp}
                                sx={{
                                    marginY: 0.5,
                                    marginX: 1,
                                    '& .MuiOutlinedInput-root': {
                                        height: '25px',
                                        fontSize: '0.8rem',
                                        borderRadius: '8px',
                                        backgroundColor: '#F4F5F7',
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: '6px 8px',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            display={'flex'}
                            justifyContent={'center'}
                            flexDirection={'column'}
                            sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body1"
                                        sx={{fontSize: '0.7rem', height: '30px', paddingTop: 1.5 }}>
                                {'연락처 : '}
                            </Typography>
                        </Grid>


                        <Grid
                            item
                            xs={8}
                            display={'flex'}
                            justifyContent={'center'}
                            flexDirection={'column'}
                            sx={{textAlign: 'left'}} // 왼쪽 정렬로 수정
                        >
                            <TextField
                                size="small"
                                fullWidth
                                name="tell"
                                value={formData.tell}
                                onChange={handleInputChange}
                                disabled={isComp}
                                sx={{
                                    marginY: 0.5,
                                    marginX: 1,
                                    '& .MuiOutlinedInput-root': {
                                        height: '25px',
                                        fontSize: '0.8rem',
                                        borderRadius: '8px',
                                        backgroundColor: '#F4F5F7',
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: '6px 8px',
                                    },
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={3}
                            display={'flex'}
                            justifyContent={'center'}
                            flexDirection={'column'}
                            sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >

                            <Typography variant="body1"
                                        sx={{fontSize: '0.7rem', height: '20px', paddingTop: 1}}>
                                {'결제 URL : '}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            display={'flex'}
                            justifyContent={'center'}
                            flexDirection={'column'}
                            sx={{textAlign: 'left'}} // 왼쪽 정렬로 수정
                        >
                            <Typography variant="body1"
                                        sx={{marginX: 1.5, fontSize: '0.8rem', color: '#808080', paddingTop: 0 }}>
                                {url}
                            </Typography>
                    </Grid>
                </Grid>
            }
            <Grid
                item
                container
                xs={11}
                sx={{
                    position: 'fixed',  // 절대 위치 사용
                    bottom: '8vh',  // 하단에서 8vh 위에 위치
                    backgroundColor: '#D4D4D4',
                    borderTopLeftRadius: '16px',  // 상단 좌측 모서리 라운드 처리
                    borderTopRightRadius: '16px',  // 상단 우측 모서리 라운드 처리
                    overflow: 'hidden',  // 자식 요소가 부모 요소를 넘지 않도록 설정
                }}
                display={'flex'}
                justifyContent={'center'}  // 수평 중앙 정렬
                alignItems={'center'}  // 수직 중앙 정렬
            >
                <Grid item xs={8}
                      display={'flex'}
                      justifyContent={'center'}
                >
                    <Typography variant={'body1'}>
                        {'합계 : '}
                        <span style={{fontWeight: 'bold', fontSize: '20px', color: 'black'}}>
                            {totalPrice.toLocaleString() + '원'}
                        </span>
                    </Typography>
                </Grid>
                <Grid item xs={4}
                      display={'flex'}
                      justifyContent={'center'}  // 수평 중앙 정렬
                >
                    {isComp ?
                        <Button
                            fullWidth
                            sx={{
                                marginX: 1,
                                marginY: 1,
                                paddingY: 2,
                                height: '65px',
                                backgroundColor: color,
                                color: 'white',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderRadius: 10,
                                '&:hover': {backgroundColor: '#999999'}
                            }}
                            onClick={handleShare}
                        >
                            링크 전송
                        </Button>
                        :
                        isSet ?
                            <Button
                                fullWidth
                                sx={{
                                    marginX: 1,
                                    marginY: 1,
                                    paddingY: 2,
                                    height: '65px',
                                    backgroundColor: color,
                                    color: 'white',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    borderRadius: 10,
                                    '&:hover': {backgroundColor: '#999999'}
                                }}
                                onClick={checkPrice}
                            >
                                결제 링크 <br/>
                                보내기
                            </Button>
                            :
                            <Button
                                fullWidth
                                sx={{
                                    marginX: 1,
                                    marginY: 1,
                                    paddingY: 2,
                                    height: '65px',
                                    backgroundColor: color,
                                    color: 'white',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    borderRadius: 10,
                                    '&:hover': {backgroundColor: '#999999'}
                                }}
                                onClick={() => setIsSet(true)}
                            >
                                결제 링크 <br/>
                                만들기
                            </Button>}
                </Grid>
            </Grid>
            <OverModal open={overModal} setOpen={setOverModal} onConfirm={handleSumit}/>

        </>
    );
};

export default SelectedList;
