import React, {useState} from 'react';
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import addIcon from '../../images/addbutton.png';
import axios from "../../axiosInstance";
import {memberState} from "../../store/memberStore";
import {useNavigate} from "react-router-dom";

const SRegItem = () => {
    const color = useRecoilValue(colorState);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        productName: '',
        price: '',
        description: '',
        image: null
    });
    const [selectedImage, setSelectedImage] = useState(addIcon);
    const memberInfo = useRecoilValue(memberState);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            setFormData(prev => ({...prev, image: file}));
        }
    };

    const handleSubmit = async () => {
        const {productName, price, description, image} = formData;
        // 유효성 검사
        if (!formData.productName) {
            alert("상품명을 입력해주세요.");
            return;
        }
        if (!formData.price) {
            alert("가격을 입력해주세요.");
            return;
        }
        if (!formData.description) {
            alert("상품 설명을 입력해주세요.");
            return;
        }
        if (!formData.image) {
            alert("이미지를 등록해주세요.");
            return;
        }

        const submissionData = new FormData();
        submissionData.append('image', image);
        submissionData.append('productName', productName);
        submissionData.append('price', price);
        submissionData.append('description', description);

        axios.post(`/api/store/${memberInfo.memCode}`, submissionData, {
            headers: {'Content-Type': 'multipart/form-data'},
        })
            .then(() => {
                alert("상품이 성공적으로 등록되었습니다.");
                navigate('/item')

            })
            .catch((error) => {
                alert("상품 등록 중 오류가 발생했습니다.");
            });

    };

    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Grid container justifyContent="center" sx={{
                marginX: 2,
                height: '82vh',
                backgroundColor: '#FFFFFF',
                border: '1px solid #e0e0e0',
                borderRadius: 5,
                padding: 1.5
            }}>
                <Grid item xs={10} justifyContent="center">
                    <Box sx={{
                        backgroundColor: '#F4F5F7',
                        marginY: 2,
                        borderRadius: 3,
                        width: '100%',
                        height: '28vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        paddingTop: 1,
                    }} onClick={() => document.getElementById('fileInput').click()}>
                        <img style={{
                            width: selectedImage === addIcon ? '50%' : '90%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            borderRadius: '8px'
                        }} src={selectedImage} alt="add icon"/>
                        {selectedImage === addIcon &&
                            <Typography variant="body1" sx={{marginTop: 2}}>사진 등록</Typography>}
                    </Box>
                    <input id="fileInput" type="file" accept="image/*" style={{display: 'none'}}
                           onChange={handleImageChange}/>
                </Grid>

                <Grid container spacing={2} justifyContent="center">
                    <Grid item container xs={10} spacing={1}>
                        <Grid item xs={3} display="flex" alignItems="center" justifyContent="end">
                            <Typography variant="body1" sx={{fontSize: '15px', color: '#777777'}}>상품명 :</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField size="small" fullWidth name="productName" value={formData.productName}
                                       onChange={handleInputChange}
                                       sx={{
                                           '& .MuiOutlinedInput-root': {
                                               height: '30px',
                                               fontSize: '0.8rem',
                                               borderRadius: '8px',
                                               backgroundColor: '#F4F5F7'
                                           }, '& .MuiInputBase-input': {padding: '6px 8px'}
                                       }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container xs={10} spacing={1}>
                        <Grid item xs={3} display="flex" alignItems="center" justifyContent="end">
                            <Typography variant="body1" sx={{fontSize: '15px', color: '#777777'}}>가격 :</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField size="small" fullWidth name="price" value={formData.price}
                                       onChange={handleInputChange} sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '30px',
                                    fontSize: '0.8rem',
                                    borderRadius: '8px',
                                    backgroundColor: '#F4F5F7'
                                }, '& .MuiInputBase-input': {padding: '6px 8px'}
                            }}/>
                        </Grid>
                    </Grid>

                    <Grid item container xs={10} spacing={1}>
                        <Grid item xs={3} display="flex" alignItems="start" justifyContent="end">
                            <Typography variant="body1" sx={{paddingTop: 1, fontSize: '13px', color: '#777777'}}>
                                상품 설명 :
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField size="small" multiline fullWidth rows={8} name="description"
                                       value={formData.description} onChange={handleInputChange} sx={{
                                '& .MuiOutlinedInput-root': {
                                    fontSize: '0.8rem',
                                    borderRadius: '8px',
                                    backgroundColor: '#F4F5F7'
                                }
                            }}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={5} display="flex" justifyContent="center">
                        <Button fullWidth sx={{
                            marginTop: 3,
                            height: '35px',
                            backgroundColor: color,
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            borderRadius: 3,
                            '&:hover': {backgroundColor: '#999999'}
                        }} onClick={handleSubmit}>
                            상품등록
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SRegItem;
