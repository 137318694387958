import React, {useEffect, useState} from 'react';
import addImg from '../../images/addbutton.png';
import {Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {memberState} from "../../store/memberStore";
import axios from "../../axiosInstance";

const SItem = () => {

    const navigate = useNavigate();
    const memberInfo = useRecoilValue(memberState);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios.get(`/api/store/${memberInfo.memCode}`).then((res) => {
            setData(res.data);
        }).catch();
    }

    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.ITEM_IMG}`;
    });

    const handleNav = (data) => {
        // /store/linkDetail로 이동하면서 데이터 d를 함께 전달
        navigate('/editItem', {state: {data: data}});
    };

    return (
        <>
            <Grid
                sx={{
                    marginBottom: 2,
                    padding: 1,
                }}
                container
                display={'flex'}
                justifyContent="space-around"
            >
                {data.map((item, index) => (
                    <Grid item xs={5.5} display="flex" justifyContent="center" alignItems="center"
                          sx={{
                              marginTop: 1,
                              height: '30vh',
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #e0e0e0',
                              borderRadius: 5,
                              padding: 1.5,
                              flexDirection: 'column',  // 상하로 배치
                          }}
                          key={index}
                          onClick={() => (handleNav(item))}
                    >
                        <img
                            style={{
                                width: '90%',
                                height: '80%',
                                objectFit: 'fill',  // 영역에 꽉 차게
                                borderRadius: '10px',  // 모서리 라운딩
                            }}
                            src={imageURL(item)} alt="item image"
                        />
                        <Typography variant={'body2'} sx={{marginTop: 2, color: '#5f5f5f'}}>
                            {'상품명 : ' + item.ITEM_NAME}
                        </Typography>
                        <Typography variant={'body2'} sx={{color: '#5f5f5f', marginBottom: 1}}>
                            {' 가 격 : ' + item.ITEM_PRICE + '원'}
                        </Typography>
                    </Grid>
                ))
                }

                <Grid item xs={5.5} display="flex" alignItems="center"
                      sx={{
                          height: '30vh',
                          backgroundColor: '#FFFFFF',
                          border: '1px solid #e0e0e0',
                          borderRadius: 5,
                          paddingTop: 3,
                          paddingX: 5,
                          flexDirection: 'column',  // 상하로 배치
                          marginTop: 1
                      }}
                      onClick={() => (navigate('/regItem'))}
                >
                    <img
                        style={{
                            maxHeight: '55%',
                            objectFit: 'contain',
                        }}
                        src={addImg} alt="add image"
                    />
                    <Typography variant={'body2'} sx={{marginY: 5, color: '#5f5f5f'}}>
                        상품등록
                    </Typography>
                </Grid>
                {data.length / 2 !== 1 &&
                    <Grid item xs={5.5} display="flex" justifyContent={'center'}
                          alignItems="center">
                    </Grid>
                }

            </Grid>
        </>
    );
};

export default SItem;
