import React, {useEffect, useState} from 'react';
import {useRecoilValue} from "recoil";
import {memberState} from "../../store/memberStore";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import cancelIcon from "../../images/cancel.png";
import applyIcon from "../../images/apply.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "../../axiosInstance";
import {useNavigate} from "react-router-dom";

const MbileTableCell = (props) => {

    return (
        <TableCell
            {...props}
            sx={{...props.sx, fontSize: '12px', padding: 0.5, paddingY: 1}}
        >
            {props.children}
        </TableCell>
    );
};

const LinkList = () => {

    const [data, setData] = useState([]);
    const memberInfo = useRecoilValue(memberState);
    const navigate = useNavigate();


    useEffect(() => {
        getData()
    }, []);
    const getData = () => {

        axios.get(`/api/store/link/${memberInfo.memCode}`).then(res => {
            setData(res.data);
        }).catch();
    }


    const handleClick = (data) => {
        // /store/linkDetail로 이동하면서 데이터 d를 함께 전달
        navigate('/linkDetail', { state: { data: data } });
    };




    return (
        <>
            <Grid container display={'flex'} justifyContent={'center'}>
                <Grid item xs={11}>
                    <TableContainer component={Paper} sx={{padding: 0, borderRadius: 2}}>
                        <Table sx={{fontSize: '8px'}} aria-label="simple table">
                            <TableHead sx={{ backgroundColor: '#D9D9D9'}}>
                                <TableRow>
                                    <MbileTableCell align="center"
                                                    sx={{padding: 0, fontSize: '10px', width: '20%'}}
                                                    component="th">
                                        구매자
                                    </MbileTableCell>
                                    <MbileTableCell align="center" component="th">
                                        연락처
                                    </MbileTableCell>
                                    <MbileTableCell align="center" component="th" colspan={1}>
                                        결제금액
                                    </MbileTableCell>
                                    <MbileTableCell align="center" component="th" colspan={1}>
                                        상세
                                    </MbileTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((d, idx) => (
                                    <>
                                        <TableRow key={idx} align="center"
                                                  sx={{
                                                      '&:hover': {
                                                          backgroundColor: 'rgba(0, 0, 0, 0.04)', // 호버 시 원하는 색상으로 변경
                                                      },
                                                  }}>
                                            <MbileTableCell align="center">
                                                {d.NAME}
                                            </MbileTableCell>
                                            <MbileTableCell align="center">
                                                {d.TELL}
                                            </MbileTableCell>
                                            <MbileTableCell align="center">
                                                {d.PRICE.toLocaleString()}
                                            </MbileTableCell>
                                            <MbileTableCell onClick={() => (handleClick(d))} align="center">
                                                {'상세'}
                                            </MbileTableCell>
                                        </TableRow>

                                    </>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

        </>
    );
};

export default LinkList;
