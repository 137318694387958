import React from 'react';

const PayList = () => {
    return (
        <>
            결제목록
        </>
    );
};

export default PayList;
