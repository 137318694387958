import React, {useEffect, useState} from 'react';
import axios from "../../axiosInstance";
import {useLocation} from "react-router-dom";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import UrlModal from "./UrlModal";

const LinkDetail = () => {

    const location = useLocation();
    const {data} = location.state || {};  // 전달된 데이터를 받음
    const [itemList, setItemList] = useState([])
    const color = useRecoilValue(colorState);
    const [url, setUrl] = useState('');
    const [overModal, setOverModal] = useState(false);
    const [confirmUrl, setConfirmUrl] = useState('https://www.ggshop.kr/confirm/')
    const [isComp, setIsComp] = useState(false)
    useEffect(() => {

        getLinkDetail();
        setUrl('https://www.ggshop.kr/link/' + data.LINK)
        if (data.CONFIRM) {
            setConfirmUrl('https://www.ggshop.kr/confirm/' + data.CONFIRM)
            setIsComp(true)
        }


    }, [data]);

    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.ITEM_IMG}`;
    });

    const getLinkDetail = () => {

        axios.get(`/api/store/link/detail/${data.LINK}`).then(res => {
            setItemList(res.data);

        }).catch();
    }

    const handleSumit = () => {

        //수령확인url 생성 엔드포인트 호출
        axios.patch(`/api/store/link/${data.LINK}`).then(res => {
            setConfirmUrl('https://www.ggshop.kr/store/' + res.data)
            setOverModal(false)
            setIsComp(true)
        }).catch();
    }

    const handleShare = async (payload) => {
        // URL이 제공되지 않으면 함수 종료
        if (!payload) {
            console.error('공유할 URL이 제공되지 않았습니다.');
            return;
        }

        // navigator.share를 지원하는지 확인
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'GHPAY 개인 결제', // 필요한 경우 제목 변경
                    url: payload, // 공유할 URL
                });
            } catch (error) {
            }
        } else {
            // URL을 클립보드에 복사
            try {
                await navigator.clipboard.writeText(payload);
                alert('URL이 클립보드에 복사되었습니다.');
            } catch (copyError) {
            }
        }
    };


    return (
        <>
            <Grid container display={'flex'} justifyContent={'center'}>

                <Typography variant={'body1'} sx={{fontSize: '17px'}}>
                    구매 상품
                </Typography>
                {itemList &&
                    itemList.map((item, index) => (
                        <>

                            <Grid item container xs={11} key={index}
                                  sx={{
                                      backgroundColor: '#FFFFFF',
                                      border: '1px solid #e0e0e0',
                                      borderRadius: 5,
                                      padding: 1,
                                  }}
                            >
                                <Grid item xs={4}>
                                    <img
                                        style={{
                                            borderRadius: '12px',
                                            width: '80px',
                                            height: '75px',
                                            objectFit: 'cover', // 이미지가 부모 영역에 꽉 차게 설정
                                        }}
                                        src={imageURL(item)} alt="add image"/>
                                </Grid>
                                <Grid item xs={2}
                                      display={'flex'}
                                      justifyContent={'center'}
                                      flexDirection={'column'}
                                      sx={{textAlign: 'right'}} // 우측 정렬 설정
                                >
                                    <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                        {'상품명 : '}
                                    </Typography>
                                    <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                        {'가  격 : '}
                                    </Typography>
                                    <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                        {'수  량 : '}
                                    </Typography>
                                    <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                        {'설  명 : '}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}
                                      display={'flex'}
                                      justifyContent={'center'}
                                      flexDirection={'column'}
                                      sx={{paddingLeft: 1}}
                                >
                                    <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                        {item.ITEM_NAME}
                                    </Typography>
                                    <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                        {(item.ITEM_PRICE * item.COUNT).toLocaleString() + '원'}
                                    </Typography>
                                    <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                        {item.COUNT}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: '0.8rem',
                                            overflow: 'hidden',       // 넘치는 내용을 숨김
                                            whiteSpace: 'nowrap',     // 한 줄로 표시
                                            textOverflow: 'ellipsis',  // 넘치는 텍스트를 ellipsis(...)로 표시
                                        }}
                                    >
                                        {item.ITEM_DESC}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </>
                    ))}


                <Grid
                    item
                    container
                    xs={11}
                    sx={{
                        position: 'absolute',  // 절대 위치 사용
                        bottom: '20vh',  // 하단에서 20vh 위에 위치
                        backgroundColor: '#FFFFFF',
                        borderRadius: 5,
                        overflow: 'hidden',  // 자식 요소가 부모 요소를 넘지 않도록 설정
                        zIndex: 999,
                    }}
                >


                    <Grid
                        item
                        xs={3}
                        sx={{textAlign: 'right'}} // 우측 정렬 설정
                    >
                        <Typography variant="body1"
                                    sx={{fontSize: '0.7rem', height: '30px', paddingTop: 1}}>
                            {'구매자 명 : '}
                        </Typography>

                    </Grid>
                    <Grid
                        item
                        xs={8}
                        sx={{textAlign: 'left'}} // 왼쪽 정렬로 수정
                    >
                        <TextField
                            size="small"
                            fullWidth
                            name="name"
                            value={data.NAME}
                            disabled={true}
                            sx={{
                                marginY: 0.5,
                                marginX: 1,
                                '& .MuiOutlinedInput-root': {
                                    height: '25px',
                                    fontSize: '0.8rem',
                                    borderRadius: '8px',
                                    backgroundColor: '#F4F5F7',
                                },
                                '& .MuiInputBase-input': {
                                    padding: '6px 8px',
                                },
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={3}
                        sx={{textAlign: 'right'}} // 우측 정렬 설정
                    >
                        <Typography variant="body1"
                                    sx={{fontSize: '0.7rem', height: '30px', paddingTop: 1}}>
                            {'연락처 : '}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        sx={{textAlign: 'left'}} // 왼쪽 정렬로 수정
                    >
                        <TextField
                            size="small"
                            fullWidth
                            name="tell"
                            value={data.TELL}
                            disabled={true}
                            sx={{
                                marginY: 0.5,
                                marginX: 1,
                                '& .MuiOutlinedInput-root': {
                                    height: '25px',
                                    fontSize: '0.8rem',
                                    borderRadius: '8px',
                                    backgroundColor: '#F4F5F7',
                                },
                                '& .MuiInputBase-input': {
                                    padding: '6px 8px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{textAlign: 'right'}} // 우측 정렬 설정
                    >
                        <Typography variant="body1"
                                    sx={{fontSize: '0.7rem', height: '30px', paddingTop: 0.2}}>
                            {'결제 URL : '}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={9}
                        sx={{textAlign: 'left'}} // 왼쪽 정렬로 수정
                    >
                        <Typography variant="body1"
                                    sx={{marginX: 1, fontSize: '0.8rem', color: '#808080', paddingTop: 0}}>
                            {url}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={3}
                        sx={{textAlign: 'right'}} // 우측 정렬 설정
                    >
                        <Typography variant="body1"
                                    sx={{fontSize: '0.7rem', height: '30px', paddingTop: 0.2}}>
                            {'수령확인 URL : '}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={9}
                        sx={{textAlign: 'left'}} // 왼쪽 정렬로 수정
                    >
                        <Typography variant="body1"
                                    sx={{
                                        marginX: 1,
                                        fontSize: '0.8rem',
                                        color: '#808080',
                                        paddingTop: 0
                                    }}>
                            {confirmUrl}
                        </Typography>
                    </Grid>
                </Grid>


                <Grid
                    item
                    container
                    spacing={1}
                    xs={11}
                    sx={{
                        position: 'absolute',  // 절대 위치 사용
                        bottom: '8vh',  // 하단에서 8vh 위에 위치
                        backgroundColor: '#D4D4D4',
                        borderTopLeftRadius: '16px',  // 상단 좌측 모서리 라운드 처리
                        borderTopRightRadius: '16px',  // 상단 우측 모서리 라운드 처리
                        overflow: 'hidden',  // 자식 요소가 부모 요소를 넘지 않도록 설정
                    }}
                    display={'flex'}
                    justifyContent={'center'}  // 수평 중앙 정렬
                    alignItems={'center'}  // 수직 중앙 정렬
                >
                    <Grid item xs={6}
                          display={'flex'}
                          justifyContent={'center'}
                    >
                        <Button
                            fullWidth
                            sx={{
                                marginX: 1,
                                marginY: 1,
                                paddingY: 2,
                                height: '60px',
                                backgroundColor: color,
                                color: 'white',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderRadius: 3,
                                '&:hover': {backgroundColor: '#999999'}
                            }}
                            onClick={() => (handleShare(url))}
                        >
                            결제 URL 보내기
                        </Button>
                    </Grid>
                    <Grid item xs={6}
                          display={'flex'}
                          justifyContent={'center'}  // 수평 중앙 정렬
                    >
                        {isComp ?
                            <Button
                                fullWidth
                                sx={{
                                    marginX: 1,
                                    marginY: 1,
                                    paddingY: 2,
                                    height: '60px',
                                    backgroundColor: color,
                                    color: 'white',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    borderRadius: 3,
                                    '&:hover': {backgroundColor: '#999999'}
                                }}
                                onClick={() => (handleShare(confirmUrl))}
                            >
                                수령 확인 URL <br/>
                                보내기
                            </Button>
                            :
                            <Button
                                fullWidth
                                sx={{
                                    marginX: 1,
                                    marginY: 1,
                                    paddingY: 2,
                                    height: '60px',
                                    backgroundColor: color,
                                    color: 'white',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    borderRadius: 3,
                                    '&:hover': {backgroundColor: '#999999'}
                                }}
                                onClick={() => setOverModal(true)}
                            >
                                수령 확인 URL <br/>
                                생성하기
                            </Button>
                        }

                    </Grid>
                </Grid>
            </Grid>
            <UrlModal open={overModal} setOpen={setOverModal} onConfirm={handleSumit}/>

        </>
    );
};

export default LinkDetail;
