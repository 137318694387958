import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import SelectedList from "./SelectedList";
import ItemList from "./ItemList";
import ItemDetail from "./ItemDetail";

const SLink = () => {

    const [isSelect, setIsSelect] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [selectItem, setSelectItem] = useState({});
    const [itemList, setItemList] = useState([]);

    useEffect(() => {
    }, []);

    return (
        <>
            <Grid container sx={{ paddingBottom: '8vh', height: '100%' }} display={'flex'} justifyContent={'space-around'}>
                {isSelect && isDetail ?
                    <ItemDetail setIsSelect={setIsSelect} setIsDetail={setIsDetail} data={selectItem} setItemList={setItemList}/>
                    :
                    isSelect && !isDetail ?
                        <ItemList setIsDetail={setIsDetail} setSelectItem={setSelectItem}/>
                        :
                        <SelectedList itemList={itemList} setIsSelect={setIsSelect}/>
                }
            </Grid>
        </>
    );
};

export default SLink;
