import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import cardwImg from "../../images/cardw.png";
import cardImg from "../../images/card.png";
import itemwImg from "../../images/itemw.png";
import itemImg from "../../images/item.png";
import paylistwImg from "../../images/paylistw.png";
import paylistImg from "../../images/paylist.png";
import myshopwImg from "../../images/myshopw.png";
import myshopImg from "../../images/myshop.png";

const ANavbar = () => {

    const navigate = useNavigate();
    const [value, setValue] = React.useState('/admin/link');
    const color = useRecoilValue(colorState);

    useEffect(() => {
        handleChange()
        pathHandler();
    }, [value]);
    const handleChange = () => {
        setValue(value);
    };

    const pathHandler = () => {
        navigate(value);
    };


    return (
        <>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{
                    width: '100%',
                    height: '8vh',
                    position: 'fixed',
                    bottom: 0,
                    backgroundColor: '#fff', // 하단 고정, 배경색 흰색
                    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.24)', // 상단에 그림자 추가
                    zIndex: 9999
                }} // 하단 고정, 배경색 흰색
            >
                <BottomNavigationAction
                    label="결제 링크"
                    value={'/admin/link'}
                    icon={
                        <img
                            src={value === '/admin/link' ? cardwImg : cardImg}
                            alt="icon"
                            style={{ width: 22, height: 22, marginBottom: 10, marginTop: 2 }}
                        />
                    }
                    sx={{
                        '& .MuiBottomNavigationAction-label': {
                            fontSize: '12px',  // 글자 크기
                        },
                        '&.Mui-selected': {color: 'white', fontWeight: 'bold', backgroundColor: color }, // 선택된 아이템의 색상을 회색으로 설정
                        color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                    }}
                />

                <BottomNavigationAction
                    label="결제 목록"
                    value={'/admin/payList'}
                    icon={
                        <img
                            src={value === '/admin/payList' ? paylistwImg : paylistImg}
                            alt="icon"
                            style={{ width: 22, height: 22, marginBottom: 10, marginTop: 2 }}
                        />
                    }
                    sx={{
                        '& .MuiBottomNavigationAction-label': {
                            fontSize: '12px',  // 글자 크기
                        },
                        '&.Mui-selected': {color: 'white', fontWeight: 'bold', backgroundColor: color }, // 선택된 아이템의 색상을 회색으로 설정
                        color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                    }}
                />
                <BottomNavigationAction
                    label="상품 등록"
                    value={'/admin/regItem'}
                    icon={
                        <img
                            src={value === '/admin/regItem' ? itemwImg : itemImg}
                            alt="icon"
                            style={{ width: 22, height: 22, marginBottom: 10, marginTop: 2 }}
                        />
                    }
                    sx={{
                        '& .MuiBottomNavigationAction-label': {
                            fontSize: '12px',  // 글자 크기
                        },
                        '&.Mui-selected': {color: 'white', fontWeight: 'bold', backgroundColor: color }, // 선택된 아이템의 색상을 회색으로 설정
                        color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                    }}
                />
                <BottomNavigationAction
                    label="회원 목록 "
                    value={'/admin/member'}
                    icon={
                        <img
                            src={value === '/admin/member' ? myshopwImg : myshopImg}
                            alt="icon"
                            style={{ width: 22, height: 22, marginBottom: 10, marginTop: 2 }}
                        />
                    }
                    sx={{
                        '& .MuiBottomNavigationAction-label': {
                            fontSize: '12px',  // 글자 크기
                        },
                        '&.Mui-selected': {color: 'white', fontWeight: 'bold', backgroundColor: color }, // 선택된 아이템의 색상을 회색으로 설정
                        color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                    }}
                />
            </BottomNavigation>
        </>
    );
};

export default ANavbar;
