import React from 'react';
import {Button, Grid, TextField, Typography} from "@mui/material";
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";

const ConfirmComp = ({ linkData }) => {
    const color = useRecoilValue(colorState);

    return (
        <>
            <Grid item container xs={11}
                  sx={{
                      backgroundColor: '#D4D4D4',
                      border: '1px solid #e0e0e0',
                      borderRadius: 5,
                      padding: 1,
                      marginTop: 2,
                  }}
                  display={'flex'}
                  justifyContent={'center'}
            >
                <Grid item xs={11}
                      display={'flex'}
                      justifyContent={'center'}

                >
                    <Typography variant="body1"
                                sx={{
                                    fontSize: '0.8rem',
                                    color: '#000000',
                                    marginLeft: 0,
                                }}>
                        {'파손이나 반품의 의사가 있을 경우 판매자와 상의하시기 바랍니다. ' +
                            '상품 확인 및 수령을 완료하신 후에는 상품 미수령의 이유로 인한 '}
                        <span style={{fontWeight: 'bold', color: 'red'}}>
                         결제 취소가 불가능
                        </span>
                        {'합니다. 이 후 제품의 A/S, 파손 등의 불만족으로 인한 취소는 ' +
                            '판매자와 고객의 합의로 진행되며 저희 GHPAY는 이를 책임지지 않습니다'}
                    </Typography>
                </Grid>

            </Grid>

            <Grid item xs={11}
                  display={'flex'}
                  justifyContent={'center'}
                  sx={{marginTop: 5}}
            >
                <Typography variant={'body1'} sx={{
                    fontSize: '1.1rem',
                    color: '#000000', fontWeight: 'bold', marginLeft: 2, padding: 0.5
                }}>
                    상품을 확인 및 수령하였다면 아래의 본인 인증과 확인서명에 싸인해주세요.
                </Typography>
            </Grid>

            <Grid item xs={11}
                  display={'flex'}
                  justifyContent={'center'}
                  sx={{marginTop: 5}}
            >
                <Grid
                    item
                    xs={3}
                    display={'flex'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    sx={{textAlign: 'right'}} // 왼쪽 정렬로 수정
                >
                    <Typography variant="body1"
                                sx={{fontSize: '0.7rem', height: '30px', padding: 0.5, paddingTop: 0.5}}>
                        {'구매자 명 : '}
                    </Typography>
                    <Typography variant="body1"
                                sx={{fontSize: '0.7rem', height: '30px', padding: 0.5, paddingTop: 1}}>
                        {'연락처 : '}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    display={'flex'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    sx={{textAlign: 'left'}} // 왼쪽 정렬로 수정
                >
                    <TextField
                        size="small"
                        fullWidth
                        name="name"
                        value={linkData.NAME}
                        disabled={true}
                        sx={{
                            marginY: 0.5,
                            marginX: 1,
                            '& .MuiOutlinedInput-root': {
                                height: '25px',
                                fontSize: '0.8rem',
                                borderRadius: '8px',
                                backgroundColor: '#F4F5F7',
                            },
                            '& .MuiInputBase-input': {
                                padding: '6px 8px',
                            },
                        }}
                    />
                    <TextField
                        size="small"
                        fullWidth
                        name="tell"
                        value={linkData.TELL}
                        disabled={true}
                        sx={{
                            marginY: 0.5,
                            marginX: 1,
                            '& .MuiOutlinedInput-root': {
                                height: '25px',
                                fontSize: '0.8rem',
                                borderRadius: '8px',
                                backgroundColor: '#F4F5F7',
                            },
                            '& .MuiInputBase-input': {
                                padding: '6px 8px',
                            },
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    sx={{ marginX: 1 }}
                >
                    <Button
                        fullWidth
                        sx={{
                            marginX: 1,
                            marginY: 1,
                            paddingY: 2,
                            height: '65px',
                            backgroundColor: color,
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            borderRadius: 3,
                            '&:hover': {backgroundColor: '#999999'}
                        }}
                    >
                        본인<br/>
                        인증
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={11}
                  display={'flex'}
                  justifyContent={'center'}
                  sx={{marginTop: 5}}
            >
                확인서명
            </Grid>

            <Grid item xs={11}
                  display={'flex'}
                  justifyContent={'center'}
                  sx={{marginTop: 5}}
            >
                <Button
                    fullWidth
                    sx={{
                        marginX: 1,
                        marginY: 1,
                        paddingY: 2,
                        height: '50px',
                        backgroundColor: color,
                        color: 'white',
                        fontSize: '20px',
                        borderRadius: 3,
                        '&:hover': {backgroundColor: '#999999'}
                    }}
                >
                    완 료
                </Button>
            </Grid>

        </>
    );
};

export default ConfirmComp;
