import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
import {useRecoilState, useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import axios, {getCookie} from "../../axiosInstance";
import {memberState} from "../../store/memberStore";
import {useNavigate} from "react-router-dom";

const SMyShop = () => {


    const [myInfo, setMyInfo] = useState([]);
    const navigate = useNavigate();
    const [memberInfo, setMemberInfo] = useRecoilState(memberState);
    const color = useRecoilValue(colorState);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios.get(`/api/member/detailInfo/${memberInfo.memCode}`)
            .then((response) => {
                setMyInfo(response.data)
            });
    };

    const logoutHandler = () => {
        const refreshToken = getCookie('refreshToken');
        axios.delete('/api/auth/logout', {data: {refreshToken: refreshToken}}).then(() => {
            deleteToken();
            setMemberInfo({
                memCode: '',
                memRole: '',
                memId: '',
                memName: '',
                role: '',
                roleRank: '',
            })
            navigate('/');
        }).catch(error => {
            console.log(error);
        });
    }

    const deleteToken = () => {
        document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file}`;
    });


    return (
        <>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Grid container spacing={1} display={'flex'} justifyContent="center"
                      sx={{backgroundColor: '#FFFFFF', margin: 2, padding: 1, borderRadius: 3}}>
                    <Grid item xs={10}>
                        <Box sx={{
                            marginY: 2,
                            borderRadius: 3,
                            width: '100%',
                            maxHeight: '30vh',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}>
                            <img style={{
                                width: '80%',
                                height: '80%',
                                objectFit: 'contain',
                                borderRadius: '50%'
                            }} src={imageURL(myInfo.shopImg)} alt="shop icon"/>
                        </Box>
                    </Grid>
                    <Typography variant="h5">
                        회원 정보
                    </Typography>

                    <Grid item container
                          display={'flex'}
                          justifyContent={'center'}
                          xs={10}
                    >
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'아이디 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {memberInfo.memId}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'상점명 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.shopName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {myInfo.dType === '사업자' ? '사업자번호 : ' : '주민번호 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.dType === '사업자' ? myInfo.shopNum : myInfo.birthday}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'대표 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.shopOwner}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'상점연락처 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.shopTell}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'계좌번호 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.bankName + ' ' + myInfo.bankNum}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'예금주 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.bankOwner}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'주소 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.shopAddr2 + ' ' + myInfo.shopAddrDetail}
                            </Typography>
                        </Grid>

                    </Grid>


                    <Grid item xs={5}
                          display={'flex'}
                          justifyContent={'center'}  // 수평 중앙 정렬
                    >
                        <Button
                            fullWidth
                            sx={{
                                marginX: 0.5,
                                marginY: 1,
                                paddingY: 2,
                                height: '40px',
                                backgroundColor: 'white',
                                color: 'black',
                                fontSize: '15px',
                                border: '1px solid',  // 보더 두께와 스타일 설정
                                borderColor: color,  // 기본 보더 색상 (예: 검정색)
                                borderRadius: 3,
                                '&:hover': {backgroundColor: '#999999'}
                            }}
                            onClick={() => (navigate('/changePw'))}
                        >
                            비밀번호 변경
                        </Button>

                    </Grid>
                    <Grid item xs={5}
                          display={'flex'}
                          justifyContent={'center'}  // 수평 중앙 정렬
                    >
                        <Button
                            fullWidth
                            sx={{
                                marginX: 0.5,
                                marginY: 1,
                                paddingY: 2,
                                height: '40px',
                                backgroundColor: 'white',
                                color: 'black',
                                fontSize: '15px',
                                border: '1px solid',  // 보더 두께와 스타일 설정
                                borderColor: color,  // 기본 보더 색상 (예: 검정색)
                                borderRadius: 3,
                                '&:hover': {backgroundColor: '#999999'}
                            }}
                        >
                            정보 수정
                        </Button>

                    </Grid>
                    <Grid item xs={10}
                          display={'flex'}
                          justifyContent={'center'}  // 수평 중앙 정렬
                    >
                        <Button
                            fullWidth
                            sx={{
                                marginX: 0.5,
                                marginY: 3,
                                paddingY: 2,
                                height: '40px',
                                backgroundColor: 'white',
                                color: 'black',
                                fontSize: '15px',
                                border: '1px solid',  // 보더 두께와 스타일 설정
                                borderColor: color,  // 기본 보더 색상 (예: 검정색)
                                borderRadius: 3,
                                '&:hover': {backgroundColor: '#999999'}
                            }}
                            onClick={logoutHandler}
                        >
                            로그아웃
                        </Button>
                    </Grid>

                    {memberInfo.memRole === 'ROLE_ADMIN' &&
                        <Grid item xs={10}
                              display={'flex'}
                              justifyContent={'center'}  // 수평 중앙 정렬
                        >
                            <Button
                                fullWidth
                                sx={{
                                    marginX: 0.5,
                                    marginY: 3,
                                    paddingY: 2,
                                    height: '40px',
                                    backgroundColor: 'white',
                                    color: 'black',
                                    fontSize: '15px',
                                    border: '1px solid',  // 보더 두께와 스타일 설정
                                    borderColor: color,  // 기본 보더 색상 (예: 검정색)
                                    borderRadius: 3,
                                    '&:hover': {backgroundColor: '#999999'}
                                }}
                                onClick={() => (navigate('/admin/regItem'))}
                            >
                                관리자 페이지
                            </Button>
                        </Grid>}

                </Grid>
            </Box>
        </>
    );
};

export default SMyShop;
