import React, {useEffect} from 'react';
import 'react-calendar/dist/Calendar.css';
import './App.css';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useRecoilState, useRecoilValue} from "recoil";
import {memberState} from "./store/memberStore";
import axios, {getCookie} from './axiosInstance';
import SLogin from "./shop/member/SLogin";
import SLayout from "./shop/layout/SLayout";
import SLink from "./shop/link/SLink";
import SItem from "./shop/item/SItem";
import SList from "./shop/list/SList";
import SMyShop from "./shop/mypage/SMyShop";
import SRegItem from "./shop/item/SRegItem";
import LinkList from "./shop/linkList/LinkList";
import LinkDetail from "./shop/linkList/LinkDetail";
import PayUrl from "./shop/linkList/PayUrl";
import ConfirmUrl from "./shop/linkList/ConfirmUrl";
import EditItem from "./shop/item/EditItem";
import SChangePw from "./shop/mypage/SChangePw";
import SJoin from "./shop/member/SJoin";
import RegDetail from "./shop/mypage/RegDetail";
import AItem from "./shop/admin/AItem";
import PayList from "./shop/admin/PayList";
import ALayout from "./shop/layout/ALayout";
import ALinkList from "./shop/admin/ALinkList";
import MemberList from "./shop/admin/MemberList";
import MemberDetail from "./shop/admin/MemberDetail";

const theme = createTheme({
    typography: {
        fontFamily: 'Pretendard-Regular, Arial, sans-serif',
    },
});

function ProtectedRoute({children, allowedRoles}) {
    const memberInfo = useRecoilValue(memberState);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (!allowedRoles.includes(memberInfo.memRole)) {
            // 허용된 역할이 아닌 경우 /login으로 리다이렉트
            navigate('/login', {replace: true, state: {from: location}});
        }
    }, [memberInfo, allowedRoles, navigate, location]);

    return children;
}


function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const [memberInfo, setMemberInfo] = useRecoilState(memberState);


    useEffect(() => {
        sessionStorage.setItem('lastLocation', location.pathname);
    }, [location]);

    useEffect(() => {
        if (location.pathname.startsWith('/link') || location.pathname.startsWith('/confirm')) {
            return;
        }

        const accessToken = getCookie('accessToken');
        const refreshToken = getCookie('refreshToken');

        if (refreshToken) {
            axios.post('/api/auth/refreshToken', {refreshToken})
                .then(response => {
                    document.cookie = `accessToken=${response.data.accessToken}; path=/;`;
                    setMemberInfo({
                        memCode: response.data.memCode,
                        memRole: response.data.memRole,
                        memId: response.data.memId,
                        memName: response.data.memName,
                        role: response.data.role,
                        detail: response.data.detail,
                    });

                    const lastLocation = sessionStorage.getItem('lastLocation');
                    if (['ROLE_ADMIN', 'ROLE_USER'].includes(response.data.memRole)) {
                        navigate(lastLocation || '/');
                    } else {
                        navigate('/login');
                    }
                })
                .catch(error => {
                    console.error('Token refresh failed', error);
                    navigate('/login');
                });
        } else {
            navigate('/login');
        }
    }, []);


    return (
        <ThemeProvider theme={theme}>
            <Routes>
                <Route path="/login" element={<SLogin/>}/>
                <Route path="/join" element={<SJoin/>}/>
                <Route path="/link/:link" element={<PayUrl/>}/>
                <Route path="/confirm/:link" element={<ConfirmUrl/>}/>
                <Route path="/admin" element={
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN']}>
                        <ALayout/>
                    </ProtectedRoute>
                }>
                    <Route path='link' element={<ALinkList/>}/>
                    <Route path='payList' element={<PayList/>}/>
                    <Route path='regItem' element={<AItem/>}/>
                    <Route path='member' element={<MemberList/>}/>
                    <Route path='linkDetail' element={<LinkDetail/>}/>
                    <Route path='memberDetail' element={<MemberDetail/>}/>
                </Route>
                <Route path="/" element={
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}>
                        <SLayout/>
                    </ProtectedRoute>
                }>
                    <Route index element={<SLink/>}/>
                    <Route path='link' element={<SLink/>}/>
                    <Route path='changePw' element={<SChangePw/>}/>
                    <Route path='linkDetail' element={<LinkDetail/>}/>
                    <Route path='linkList' element={<LinkList/>}/>
                    <Route path='item' element={<SItem/>}/>
                    <Route path='editItem' element={<EditItem/>}/>
                    <Route path='list' element={<SList/>}/>
                    <Route path='myshop' element={<SMyShop/>}/>
                    <Route path='regItem' element={<SRegItem/>}/>
                    <Route path='regDetail' element={<RegDetail/>}/>
                </Route>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
