import React from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import axios from "axios";

const PayInfo = ({ linkData, linkUrl, setIsComp }) => {

    const color = useRecoilValue(colorState);
    const completeHandler = () => {

        axios.get(`/api/auth/updateStatus/${linkUrl}`).then(res => {
            setIsComp(true);
        }).catch();
    }

    return (
        <>
            <Grid
                item
                container
                spacing={1}
                xs={11}
                sx={{
                    position: 'absolute',  // 절대 위치 사용
                    bottom: '0vh',  // 하단에서 8vh 위에 위치
                    backgroundColor: '#D4D4D4',
                    borderTopLeftRadius: '16px',  // 상단 좌측 모서리 라운드 처리
                    borderTopRightRadius: '16px',  // 상단 우측 모서리 라운드 처리
                    overflow: 'hidden',  // 자식 요소가 부모 요소를 넘지 않도록 설정
                }}
                display={'flex'}
                justifyContent={'center'}  // 수평 중앙 정렬
                alignItems={'start'}  // 수직 중앙 정렬
            >
                <Grid item xs={8}
                      display={'flex'}
                      justifyContent={''}
                      flexDirection={'column'}
                >
                    <Typography variant="body1"
                                sx={{fontSize: '0.8rem', height: '20px', color: '#757575', marginLeft: 2, padding: 0.5, paddingTop: 1}}>
                        {'내용 확인 후 결제해 주세요.'}
                    </Typography>
                    <Typography variant={'body1'}   sx={{fontSize: '1.1rem', color: '#757575', height: '30px', marginLeft: 2, padding: 0.5}}>
                        {'합계 : '}
                        <span style={{fontWeight: 'bold', fontSize: '22px', color: 'black'}}>
                            {parseInt(linkData.PRICE).toLocaleString() + '원'}
                        </span>
                    </Typography>
                </Grid>
                <Grid item xs={4}
                      display={'flex'}
                      justifyContent={'center'}  // 수평 중앙 정렬
                >
                    <Button
                        fullWidth
                        sx={{
                            marginX: 1,
                            marginY: 1,
                            paddingY: 2,
                            height: '60px',
                            backgroundColor: color,
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            borderRadius: 10,
                            '&:hover': {backgroundColor: '#999999'}
                        }}
                        onClick={completeHandler}
                    >
                        결제하기
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default PayInfo;
