import React, {createContext, useEffect, useState} from 'react';
import {Backdrop, Box, Container} from "@mui/material";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {loadingState} from "../../store/loadingStore";
import SHeader from "./SHeader";
import SNavbar from "./SNavbar";
import axios from "../../axiosInstance";
import {memberState} from "../../store/memberStore";

export const DetailContext = createContext();

const SLayout = () => {

    const loading = useRecoilValue(loadingState);
    const [haveDetail, setHaveDetail] = useState(true);
    const memberInfo = useRecoilValue(memberState);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        handleDetail();
    }, [memberInfo.memCode]); // memberInfo.memCode가 변경될 때만 실행

    useEffect(() => {
        regDetail();
    }, [location]); // location이 변경될 때만 실행

    useEffect(() => {
        if (haveDetail) {
            navigate('/');
        }
    }, [haveDetail]);

    const regDetail = () => {
        if (haveDetail) {
            return;
        }
        navigate('/regDetail');
    }

    const handleDetail = () => {
        axios.get(`/api/member/detail/${memberInfo.memCode}`).then((res) => {
            setHaveDetail(res.data === 'Y' ? true : false);
        });
    }


    return (
        <DetailContext.Provider value={setHaveDetail}>
            <Container sx={{height: '100%', backgroundColor: '#F4F5F7', padding: 0}}>
                <Box sx={{height: '100%'}}>
                    <SHeader/>
                    <Container sx={{padding: 0, paddingTop: '50px', paddingBottom: '8vh', minHeight: '100vh'}}>
                        <Backdrop
                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, marginBottom: '8vh'}}
                            open={loading}
                        >
                        </Backdrop>
                        <Outlet/>
                    </Container>
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <SNavbar/>
                </Box>
            </Container>
        </DetailContext.Provider>
    );
};

export default SLayout;
