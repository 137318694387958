import React, {useEffect, useState} from 'react';
import DaumPostcode from "react-daum-postcode";
import { Box, Button, FormControl, Grid, TextField, Typography} from "@mui/material";
import profile from "../../images/profile.png";
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import Modal from "@mui/material/Modal";
import axios from "../../axiosInstance";
import {memberState} from "../../store/memberStore";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",

    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
const EditInfo = ({step, setStep, setFormData, formData}) => {

        const [fileName, setFileName] = useState('');
        const color = useRecoilValue(colorState);
        const [open, setOpen] = useState(false);
        const [bankName, setBankName] = useState([]);
        const memberInfo = useRecoilValue(memberState);
        const [profileImage, setProfileImage] = useState(profile);
        const [selectedFile, setSelectedFile] = useState(null);

        useEffect(() => {
            getBankName();
        }, []);

        const handleChange = (e) => {
            const field = e.target.name;
            setFormData({...formData, [field]: e.target.value});
        };

        const completeHandler = (data) => {
            formData.shopAddr1 = data.zonecode;
            formData.shopAddr2 = data.roadAddress;
            handleClose();
        };

        const handleOpen = () => {
            setOpen(true);
        };

        const handleClose = () => setOpen(false);

        const getBankName = () => {
            axios.get('/api/member/bankList').then((response) => {
                setBankName(response.data);
            });
        };

        const handleSubmit = (event) => {
            event.preventDefault();

            // 연락처 전처리
            const value = formData.shopTell.replace(/\D/g, '');

            // 값이 10자리 또는 11자리가 아니면 빈 문자열로 초기화
            if (value.length !== 10 && value.length !== 11) {
                alert('연락처를 확인해주세요.');
                setFormData({...formData, shopTell: ''});
                return;
            }

            if (!selectedFile) {
                alert('상점이미지를 등록해주세요.');
                return;
            }

            // 데이터 처리
            const data = new FormData();
            for (const key in formData) {
                data.append(key, formData[key]);
            }

            if (selectedFile) {
                const imgData = new FormData();
                imgData.append('imgData', selectedFile);

                axios.post(`/api/member/profile/${memberInfo.memCode}`, imgData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                }).catch((error) => {
                    console.error('파일 업로드 실패:', error);
                });
            }

            axios.post(`/api/member/detail/${memberInfo.memCode}`, data).then(async (response) => {
                await shopImgHandler();
                setStep(3);
            }).catch((error) => {
                alert('정보 수정에 실패했습니다.');
            });
        };

        const shopImgHandler = async () => {
            if (selectedFile) {
                const imgData = new FormData();
                imgData.append('imgData', selectedFile);

                await axios.post(`/api/member/profile/${memberInfo.memCode}`, imgData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                }).catch((error) => {
                    console.error('파일 업로드 실패:', error);
                });
            }

        }

        const handleBoxClick = () => {
            document.getElementById('profileInput').click();
        };

        const handleProfileChange = (e) => {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    setProfileImage(event.target.result);
                };
                reader.readAsDataURL(file);
                setSelectedFile(file);
            }
        };

        const handleFileChange = (e) => {
            const file = e.target.files[0];
            if (file) {
                setFileName(file.name);
                setFormData({...formData, joinFile: file});
            }
        };

        const handleFileClick = () => {
            document.getElementById('fileInput').click();
        };
        return (
            <>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Grid container display={'flex'} justifyContent="center"
                          sx={{backgroundColor: '#FFFFFF', margin: 2, marginTop: 0, padding: 1, borderRadius: 3}}>
                        <Grid item xs={10}>
                            <Box
                                onClick={handleBoxClick}
                                sx={{
                                    marginY: 2,
                                    borderRadius: 3,
                                    width: '100%',
                                    maxHeight: '30vh',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <img style={{
                                    width: '80%',
                                    height: '80%',
                                    objectFit: 'contain',
                                    borderRadius: '50%'  // 이미지를 둥글게 설정
                                }} src={profileImage} alt="add icon"/>
                                <Typography variant="caption" sx={{textAlign: 'center', marginTop: 1}}>
                                    상점이미지등록
                                </Typography>
                            </Box>
                            <input
                                type="file"
                                id="profileInput"
                                style={{display: 'none'}}
                                onChange={handleProfileChange}
                            />
                        </Grid>
                        <Typography variant="h5">
                            회원 정보
                        </Typography>

                        <Grid container component={'form'}
                              display={'flex'}
                              justifyContent={'center'}
                              xs={11}
                              onSubmit={handleSubmit}
                        >
                            <Grid item xs={3}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{textAlign: 'right'}} // 우측 정렬 설정
                            >
                                <Typography variant="body2" sx={{fontSize: '12px', color: '#A0A0A0', paddingTop: 1}}>
                                    {'상점명 : '}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  sx={{paddingTop: 1, paddingLeft: 1}}
                            >
                                <TextField
                                    onChange={handleChange}
                                    type="text"
                                    name="shopName"
                                    id="shopName"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={formData.shopName}
                                    inputProps={{style: {fontSize: 12, padding: 2}}}
                                    required={true}
                                    size={'small'}
                                    sx={{
                                        height: '20px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '5px',  // border-radius 설정.
                                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                                        }
                                    }}
                                />
                            </Grid>
                            {formData.dType === '사업자' ?
                                <>
                                    <Grid item xs={3}
                                          display={'flex'}
                                          justifyContent={'center'}
                                          flexDirection={'column'}
                                          sx={{textAlign: 'right'}} // 우측 정렬 설정
                                    >
                                        <Typography variant="body2"
                                                    sx={{fontSize: '12px', color: '#A0A0A0', paddingTop: 1}}>
                                            {'사업자번호 : '}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}
                                          display={'flex'}
                                          justifyContent={'center'}
                                          sx={{paddingTop: 1, paddingLeft: 1}}
                                    >
                                        <TextField
                                            onChange={handleChange}
                                            type="text"
                                            name="shopNum"
                                            id="shopNum"
                                            value={formData.shopNum}
                                            variant="outlined"
                                            fullWidth={true}
                                            inputProps={{style: {fontSize: 12, padding: 2}}}
                                            required={true}
                                            size={'small'}
                                            sx={{
                                                height: '20px',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '5px',  // border-radius 설정.
                                                    backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                                                }
                                            }}
                                        />
                                    </Grid>
                                </>

                                :
                                <>
                                    <Grid item xs={3}
                                          display={'flex'}
                                          justifyContent={'center'}
                                          flexDirection={'column'}
                                          sx={{textAlign: 'right'}} // 우측 정렬 설정
                                    >
                                        <Typography variant="body2"
                                                    sx={{fontSize: '12px', color: '#A0A0A0', paddingTop: 1}}>
                                            {'주민번호 : '}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}
                                          display={'flex'}
                                          justifyContent={'center'}
                                          sx={{paddingTop: 1, paddingLeft: 1}}
                                    >
                                        <TextField
                                            onChange={handleChange}
                                            type="text"
                                            name="birthday"
                                            id="birthday"
                                            value={formData.birthday}
                                            variant="outlined"
                                            fullWidth={true}
                                            inputProps={{style: {fontSize: 12, padding: 2}}}
                                            required={true}
                                            size={'small'}
                                            sx={{
                                                height: '20px',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '5px',  // border-radius 설정.
                                                    backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                                                }
                                            }}
                                        />
                                    </Grid>
                                </>
                            }


                            <Grid item xs={3}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{textAlign: 'right'}} // 우측 정렬 설정
                            >
                                <Typography variant="body2"
                                            sx={{fontSize: '12px', color: '#A0A0A0', paddingTop: 1}}>
                                    {'대 표 : '}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  sx={{paddingTop: 1, paddingLeft: 1}}
                            >
                                <TextField
                                    onChange={handleChange}
                                    type="text"
                                    name="shopOwner"
                                    id="shopOwner"
                                    value={formData.shopOwner}
                                    variant="outlined"
                                    fullWidth={true}
                                    inputProps={{style: {fontSize: 12, padding: 2}}}
                                    required={true}
                                    size={'small'}
                                    sx={{
                                        height: '20px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '5px',  // border-radius 설정.
                                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{textAlign: 'right'}} // 우측 정렬 설정
                            >
                                <Typography variant="body2"
                                            sx={{fontSize: '12px', color: '#A0A0A0', paddingTop: 1}}>
                                    {'연락처 : '}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  sx={{paddingTop: 1, paddingLeft: 1}}
                            >
                                <TextField
                                    onChange={handleChange}
                                    type="text"
                                    name="shopTell"
                                    id="shopTell"
                                    value={formData.shopTell}
                                    variant="outlined"
                                    fullWidth={true}
                                    inputProps={{style: {fontSize: 12, padding: 2}, maxLength: 13}}
                                    required={true}
                                    size={'small'}
                                    sx={{
                                        height: '20px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '5px',  // border-radius 설정.
                                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                                        }
                                    }}
                                />
                            </Grid>


                            <Grid item xs={3}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{textAlign: 'right'}} // 우측 정렬 설정
                            >
                                <Typography variant="body2"
                                            sx={{fontSize: '12px', color: '#A0A0A0', paddingTop: 1}}>
                                    {'주 소 : '}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}
                                  sx={{paddingTop: 1, paddingX: 1}}
                            >
                                <TextField
                                    onChange={handleChange}
                                    type="text"
                                    name="shopAddr2"
                                    id="shopAddr2"
                                    variant="outlined"
                                    value={formData.shopAddr2}
                                    fullWidth
                                    disabled={true}
                                    inputProps={{style: {fontSize: 12, paddingLeft: 2, paddingTop: 2, paddingBottom: 2}}}
                                    required={true}
                                    size={'small'}
                                    sx={{
                                        height: '20px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '5px',  // border-radius 설정.
                                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}
                                  onClick={handleOpen}
                                  sx={{paddingTop: 1, paddingLeft: 0}}
                            >
                                <Button
                                    sx={{

                                        backgroundColor: color,
                                        color: 'white',
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                        height: '22px',
                                        borderRadius: 1,
                                        width: '80%', // 버튼이 부모의 크기를 벗어나지 않도록 설정
                                    }}
                                >
                                    검색
                                </Button>
                            </Grid>


                            <Grid item xs={3}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{textAlign: 'right'}} // 우측 정렬 설정
                            >
                                <Typography variant="body2"
                                            sx={{fontSize: '12px', color: '#A0A0A0', paddingTop: 1}}>
                                </Typography>
                            </Grid>
                            <Grid item xs={9}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  sx={{paddingTop: 1, paddingLeft: 1}}
                            >
                                <TextField
                                    onChange={handleChange}
                                    type="text"
                                    name="shopAddrDetail"
                                    id="shopAddrDetail"
                                    value={formData.shopAddrDetail}
                                    variant="outlined"
                                    fullWidth={true}
                                    inputProps={{style: {fontSize: 12, padding: 2}}}
                                    required={true}
                                    size={'small'}
                                    sx={{
                                        height: '20px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '5px',  // border-radius 설정.
                                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                                        }
                                    }}
                                />
                            </Grid>


                            <Grid item xs={3}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{textAlign: 'right'}} // 우측 정렬 설정
                            >
                                <Typography variant="body2"
                                            sx={{fontSize: '12px', color: '#A0A0A0', paddingTop: 1}}>
                                    {'입금 계좌 : '}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  sx={{paddingTop: 1, paddingX: 1}}
                            >
                                <FormControl fullWidth variant="standard">
                                    <TextField
                                        select
                                        onChange={handleChange}
                                        name="bankName"
                                        id="bankName"
                                        value={formData.bankName}
                                        variant="outlined"
                                        fullWidth={true}
                                        inputProps={{style: {fontSize: 15, padding: 0, marginLeft: 2}}}
                                        required={true}
                                        size={'small'}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '5px',  // border-radius 설정.
                                                backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                                            }
                                        }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                    >
                                        {bankName.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{paddingTop: 1}}
                            >
                                <Button
                                    sx={{
                                        backgroundColor: color,
                                        color: 'white',
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                        height: '22px',
                                        borderRadius: 1,
                                        width: '80%', // 버튼이 부모의 크기를 벗어나지 않도록 설정

                                    }}
                                >
                                    인증
                                </Button>
                            </Grid>

                            <Grid item xs={3}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{textAlign: 'right'}} // 우측 정렬 설정
                            >
                                <Typography variant="body2"
                                            sx={{fontSize: '12px', color: '#A0A0A0', paddingTop: 1}}>
                                    {'예금주 : '}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  sx={{paddingTop: 1, paddingLeft: 1}}
                            >
                                <TextField
                                    onChange={handleChange}
                                    type="text"
                                    name="bankOwner"
                                    id="bankOwner"
                                    value={formData.bankOwner}
                                    variant="outlined"
                                    fullWidth={true}
                                    inputProps={{style: {fontSize: 12, padding: 2}}}
                                    required={true}
                                    size={'small'}
                                    sx={{
                                        height: '20px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '5px',  // border-radius 설정.
                                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                                        }
                                    }}
                                />
                            </Grid>


                            <Grid item xs={3}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{textAlign: 'right'}} // 우측 정렬 설정
                            >
                                <Typography variant="body2"
                                            sx={{fontSize: '12px', color: '#A0A0A0', paddingTop: 1}}>
                                    {'계좌번호 : '}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  sx={{paddingTop: 1, paddingLeft: 1}}
                            >
                                <TextField
                                    onChange={handleChange}
                                    type="text"
                                    name="bankNum"
                                    id="bankNum"
                                    value={formData.bankNum}
                                    variant="outlined"
                                    fullWidth={true}
                                    inputProps={{style: {fontSize: 12, padding: 2}}}
                                    required={true}
                                    size={'small'}
                                    sx={{
                                        height: '20px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '5px',  // border-radius 설정.
                                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                                        }
                                    }}
                                />
                            </Grid>


                            <Grid item xs={3}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{textAlign: 'right'}} // 우측 정렬 설정
                            >
                                <Typography variant="body2"
                                            sx={{fontSize: '12px', color: '#A0A0A0', paddingTop: 1}}>
                                    {'첨부서류 : '}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  sx={{paddingTop: 1, paddingX: 1}}
                            >
                                <TextField
                                    onChange={handleChange}
                                    type="text"
                                    name="joinFile"
                                    id="joinFile"
                                    value={fileName}
                                    variant="outlined"
                                    disabled={true}
                                    fullWidth={true}
                                    inputProps={{style: {fontSize: 12, padding: 2}}}
                                    required={true}
                                    size={'small'}
                                    sx={{
                                        height: '20px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '5px',  // border-radius 설정.
                                            backgroundColor: '#FFFFFF',  // 배경색 흰색으로 설정
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  flexDirection={'column'}
                                  sx={{paddingTop: 1}}
                            >
                                <Button
                                    onClick={handleFileClick}
                                    sx={{
                                        backgroundColor: color,
                                        color: 'white',
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                        height: '22px',
                                        borderRadius: 1,
                                        width: '80%', // 버튼이 부모의 크기를 벗어나지 않도록 설정
                                    }}
                                >
                                    첨부
                                </Button>
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{display: 'none'}}
                                    onChange={handleFileChange}
                                />
                            </Grid>


                            <Grid item xs={6}
                                  display={'flex'}
                                  justifyContent={'center'}  // 수평 중앙 정렬
                            >
                                <Button
                                    fullWidth
                                    type="submit"
                                    sx={{
                                        marginX: 0.5,
                                        marginY: 1,
                                        paddingY: 2,
                                        height: '50px',
                                        color: 'white',
                                        fontSize: '15px',
                                        border: '1px solid',  // 보더 두께와 스타일 설정
                                        backgroundColor: color,  // 기본 보더 색상 (예: 검정색)
                                        borderRadius: 3,
                                    }}

                                >
                                    정보 등록
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>


                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                            >
                                주소 검색
                            </Typography>
                            <Typography id="modal-modal-description" sx={{mt: 2}}>
                                <DaumPostcode onComplete={completeHandler}/>
                            </Typography>
                        </Box>
                    </Modal>

                </Box>
            </>
        )
            ;
    }
;

export default EditInfo;
