import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
import {useRecoilState, useRecoilValue} from "recoil";
import {memberState} from "../../store/memberStore";
import axios from "../../axiosInstance";
import {useLocation} from "react-router-dom";

const MemberDetail = () => {

    const location = useLocation();
    const [myInfo, setMyInfo] = useState([]);
    const {data} = location.state || {};  // 전달된 데이터를 받음

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios.get(`/api/member/detailInfo/${data.memCode}`)
            .then((response) => {
                setMyInfo(response.data)
            });
    };

    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file}`;
    });

    return (
        <>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Grid container spacing={1} display={'flex'} justifyContent="center"
                      sx={{backgroundColor: '#FFFFFF', margin: 2, padding: 1, borderRadius: 3}}>
                    <Grid item xs={10}>
                        <Box sx={{
                            marginY: 2,
                            borderRadius: 3,
                            width: '100%',
                            maxHeight: '30vh',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}>
                            <img style={{
                                width: '80%',
                                height: '80%',
                                objectFit: 'contain',
                                borderRadius: '50%'
                            }} src={imageURL(myInfo.shopImg)} alt="shop icon"/>
                        </Box>
                    </Grid>
                    <Typography variant="h5">
                        회원 정보
                    </Typography>

                    <Grid item container
                          display={'flex'}
                          justifyContent={'center'}
                          xs={10}
                    >
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'아이디 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {data.memId}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'상점명 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.shopName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {myInfo.dType === '사업자' ? '사업자번호 : ' : '주민번호 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.dType === '사업자' ? myInfo.shopNum : myInfo.birthday}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'대표 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.shopOwner}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'상점연락처 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.shopTell}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'계좌번호 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo && myInfo.bankName + ' ' + myInfo.bankNum}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'예금주 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo.bankOwner}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'주소 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {myInfo && myInfo.shopAddr2 + ' ' + myInfo.shopAddrDetail}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingY: 0.5}}>
                                {'가입일자 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingY: 0.5}}>
                                {data.joinDate}
                            </Typography>
                        </Grid>



                        <Grid item xs={10}>
                            <Box sx={{
                                marginY: 2,
                                borderRadius: 3,
                                width: '100%',
                                maxHeight: '30vh',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <img style={{
                                    width: '80%',
                                    height: '80%',
                                    objectFit: 'contain',
                                }} src={imageURL(myInfo.shopImg)} alt="shop icon"/>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MemberDetail;
