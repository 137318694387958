import React, {useEffect, useState} from 'react';
import { Box, Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useRecoilValue} from "recoil";
import {colorState} from "../../store/colorStore";
import {useLocation, useNavigate} from "react-router-dom";

const logo = `/images/${process.env.REACT_APP_LOGIN_IMG}`;

const SHeader = ({btnState}) => {

    const color = useRecoilValue(colorState);
    const navigate = useNavigate();
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        if (location.pathname.includes('admin')) {
            setIsAdmin(true);
        }
    }, [isAdmin]);

    return (
        <>
            <Box sx={{
                flexGrow: 1,
                padding: 1,
                position: 'fixed',
                top: 0,
                left: 0,
                width: '97%',
                zIndex: 1000,
                backgroundColor: '#F4F5F7'
            }}>
                <Typography variant="h6" component="div"
                            sx={{flexGrow: 1, marginLeft: 0, display: 'flex', alignItems: 'center'}}>
                    <img width={'150px'} src={logo} alt={'logo'}/>
                    {btnState !== 'hide' &&
                        <Button
                            id="basic-button"
                            aria-haspopup="true"
                            onClick={() => (navigate('/linkList'))}
                            sx={{
                                backgroundColor: color,
                                color: 'white',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginLeft: 'auto',
                                borderRadius: 4
                            }}
                        >
                            {isAdmin ? '일반 회원페이지' : '보낸 결제링크'}
                        </Button>}
                </Typography>
            </Box>
        </>
    );
};

export default SHeader;
