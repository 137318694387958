import React, {useState} from 'react';
import RegInfo from "./RegInfo";
import EditInfo from "./EditInfo";
import RegComp from "./RegComp";

const RegDetail = () => {

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        dType: '사업자',
        shopName: '',
        birthday: '',
        shopNum: '',
        shopOwner: '',
        shopTell: '',
        shopAddr1: '',
        shopAddr2: '',
        shopAddrDetail: '',
        bankName: '',
        bankNum: '',
        bankOwner: '',
        joinFile: ''
    })


    return (
        <>
            {step === 1 ?
                <RegInfo setFormData={setFormData} formData={formData} setStep={setStep}/>
                :
                step === 2 ?
                    <>
                        <EditInfo setFormData={setFormData} formData={formData} setStep={setStep} step={step}/>
                    </>
                    :
                    step === 3 ?
                        <>
                            <RegComp/>
                        </>
                        :
                        <>
                            잘못된 접근입니다.
                        </>

            }

        </>
    );
};

export default RegDetail;
