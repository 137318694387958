import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import {memberState} from "../../store/memberStore";
import {colorState} from "../../store/colorStore";
import {Box, Button, Grid, Typography} from "@mui/material";
import axios from "../../axiosInstance";
import {DetailContext} from "../layout/SLayout";

const RegComp = () => {

    const [myInfo, setMyInfo] = useState([]);
    const navigate = useNavigate();
    const [memberInfo, setMemberInfo] = useRecoilState(memberState);
    const color = useRecoilValue(colorState);
    const setHaveDetail = useContext(DetailContext);


    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios.get(`/api/member/detailInfo/${memberInfo.memCode}`)
            .then((response) => {
                setMyInfo(response.data)
            });
    };

    const handleConfirm = () => {
        setHaveDetail(true);
        // navigate('/store/myshop')
    }
    return (
        <>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Grid container spacing={1} display={'flex'} justifyContent="center"
                      sx={{backgroundColor: '#FFFFFF', margin: 1, padding: 1, borderRadius: 3, minHeight: '80vh'}}>
                    <Typography variant="h6" sx={{marginTop: 5}}>
                        내 상점 정보 등록 완료
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '12px', marginTop: 5}}>
                        입력하신 내용과 첨부 서류의 내용이 다를 경우 결제받으신 매출이 취소 될 수 있습니다. 하단의 내정보와 실제 정보가 일치하는지 꼭 확인해 주세요.
                    </Typography>


                    <Grid item container
                          display={'flex'}
                          justifyContent={'center'}
                          xs={11}
                          sx={{backgroundColor: '#F4F5F7', padding: 1, borderRadius: 3, marginTop: 1}}
                    >
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingTop: 0.5}}>
                                {'상점명 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingTop: 0.5}}>
                                {myInfo.shopName}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingTop: 0.5}}>
                                {myInfo.dType === '사업자' ? '사업자번호 : ' : '주민번호 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingTop: 0.5}}>
                                {myInfo.dType === '사업자' ? myInfo.shopNum : myInfo.birthday}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingTop: 0.5}}>
                                {'대표 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingTop: 0.5}}>
                                {myInfo.shopOwner}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingTop: 0.5}}>
                                {'상점연락처 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingTop: 0.5}}>
                                {myInfo.shopTell}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingTop: 0.5}}>
                                {'계좌번호 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingTop: 0.5}}>
                                {myInfo.bankName + ' ' + myInfo.bankNum}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingTop: 0.5}}>
                                {'예금주 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingTop: 0.5}}>
                                {myInfo.bankOwner}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{textAlign: 'right'}} // 우측 정렬 설정
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', color: '#A0A0A0', paddingTop: 0.5}}>
                                {'주소 : '}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              display={'flex'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              sx={{paddingLeft: 2}}
                        >
                            <Typography variant="body2" sx={{fontSize: '14px', paddingTop: 0.5}}>
                                {myInfo.shopAddr2 + ' ' + myInfo.shopAddrDetail}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={5}
                          display={'flex'}
                          justifyContent={'center'}  // 수평 중앙 정렬
                          sx={{marginTop: 5}}
                    >
                        <Button
                            fullWidth
                            sx={{
                                marginX: 0.5,
                                marginY: 1,
                                paddingY: 2,
                                height: '50px',
                                backgroundColor: color,
                                fontWeight: 'bold',
                                color: 'white',
                                fontSize: '15px',
                                border: '1px solid',  // 보더 두께와 스타일 설정
                                borderColor: color,  // 기본 보더 색상 (예: 검정색)
                                borderRadius: 3,
                                '&:hover': {backgroundColor: '#999999'}
                            }}
                            onClick={handleConfirm}
                        >
                            확인
                        </Button>

                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default RegComp;
