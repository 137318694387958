import React, {useEffect, useState} from 'react';
import {useRecoilValue} from "recoil";
import {memberState} from "../../store/memberStore";
import axios from "../../axiosInstance";
import {Grid, Typography} from "@mui/material";

const ItemList = ({setIsDetail, setSelectItem}) => {

    const memberInfo = useRecoilValue(memberState);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios.get(`/api/store/${memberInfo.memCode}`).then((res) => {
            setData(res.data);
        }).catch();
    }

    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.ITEM_IMG}`;
    });

    const handleClick = (item) => {
        setIsDetail(true);
        setSelectItem(item);
    }

    return (
        <>
            {data.map((item, index) => (
                <Grid item xs={5.5} key={index} display="flex" justifyContent="center" alignItems="center"
                      sx={{
                          marginTop: 1,
                          height: '30vh',
                          backgroundColor: '#FFFFFF',
                          border: '1px solid #e0e0e0',
                          borderRadius: 5,
                          padding: 1.5,
                          flexDirection: 'column',  // 상하로 배치
                      }}
                      onClick={() => (handleClick(item))}
                >
                    <img
                        style={{
                            width: '90%',
                            height: '80%',
                            objectFit: 'fill',  // 영역에 꽉 차게
                            borderRadius: '10px',  // 모서리 라운딩
                        }}
                        src={imageURL(item)} alt="item image"
                    />
                    <Typography variant={'body2'} sx={{marginTop: 2, color: '#5f5f5f'}}>
                        {'상품명 : ' + item.ITEM_NAME}
                    </Typography>
                    <Typography variant={'body2'} sx={{color: '#5f5f5f', marginBottom: 1}}>
                        {' 가 격 : ' + item.ITEM_PRICE + '원'}
                    </Typography>
                </Grid>
            ))
            }
            {data.length / 2 !== 1 &&
                <Grid item xs={5.5} display="flex" justifyContent={'center'}
                      alignItems="center">
                </Grid>
            }
        </>
    );
};

export default ItemList;
