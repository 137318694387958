import React, {useEffect, useState} from 'react';
import axios from "../../axiosInstance";
import {
    Grid,
    Pagination,
    PaginationItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useNavigate} from "react-router-dom";


const MbileTableCell = (props) => {

    return (
        <TableCell
            {...props}
            sx={{...props.sx, fontSize: '11px', padding: 0.5, paddingY: 1}}
        >
            {props.children}
        </TableCell>
    );
};
const ALinkList = () => {


    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };

    const getData = () => {
        axios.get(`/api/admin/link?page=${page}`).then((res) => {
            setData(res.data.content);
            setPageData(res.data);

        });
    }

    return (
        <>
            <Grid container display={'flex'} justifyContent={'center'}>
                <Grid item xs={12} sx={{height: '75vh'}}>
                    <TableContainer sx={{padding: 0.5}}>
                        <Table sx={{fontSize: '8px'}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                </TableRow>
                                <TableRow>
                                    <MbileTableCell align="center" component="th">
                                        회원명
                                    </MbileTableCell>
                                    <MbileTableCell sx={{width: '15%'}} align="center" component="th">
                                        고객명
                                    </MbileTableCell>
                                    <MbileTableCell align="center" component="th">
                                        금액
                                    </MbileTableCell>
                                    <MbileTableCell align="center" component="th">
                                        날짜
                                    </MbileTableCell>
                                    <MbileTableCell align="center" sx={{width: '10%'}} component="th">
                                        상태
                                    </MbileTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody
                            >
                                {data.map((d, idx) => (
                                    <>
                                        <TableRow key={idx} align="center" onClick={() => navigate('/admin/linkDetail', { state: { data: d } })}>
                                            <MbileTableCell align="center">
                                                {d.MEM_NAME}
                                            </MbileTableCell>
                                            <MbileTableCell align="center">
                                                {d.NAME}
                                            </MbileTableCell>
                                            <MbileTableCell align="center">
                                                {parseInt(d.PRICE).toLocaleString() + ' 원'}
                                            </MbileTableCell>
                                            <MbileTableCell align="center">
                                                {d.DATE}
                                            </MbileTableCell>
                                            <MbileTableCell align="center">
                                                {d.STATUS}
                                            </MbileTableCell>
                                        </TableRow>
                                    </>

                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <Pagination
                        count={pageData.pageSize > 0 ? Math.ceil(pageData.total / pageData.pageSize) : 0}
                        page={pageData.currentPage || 1} // pageData.page가 undefined일 경우 기본값 1 사용
                        onChange={onPageChange}
                        size="medium"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "15px 0",
                        }}
                        renderItem={(item, idx) => (
                            <PaginationItem key={idx} {...item} sx={{fontSize: 12}}/>
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ALinkList;
