import React from 'react';

const AItem = () => {
    return (
        <>
            개발중
        </>
    );
};

export default AItem;
